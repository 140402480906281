<template>
    <div class="table-container">
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <div class="table-container colored-table" v-show="!edit">
            <div class="left-content"></div>
            <div class="filter-actions" style="padding-left: 50%;">
                <b-button variant="primary" @click="addClk">
                    <i class="icon icon-plus-circle"></i>Добавить
                </b-button>
                <b-button variant="success" @click="addForecastRegionsToDB">
                    Сохранить
                </b-button>
                <b-button variant="light">
                    Сохранить новую версию
                </b-button>
            </div>
<!--            <b-table
                responsive="true"
                bordered
                striped
                hover
                small
                head-variant="light"
                sticky-header="true"
                no-border-collapse
                :fields="tableFields"
                :items="tableItems"
            >
                <template #thead-top>
                    <b-tr>
                        <b-th colspan="3"></b-th>
                        <b-th>факт 2022</b-th>
                        <b-th colspan="5" class="text-center">План</b-th>
                        <b-th colspan="4"></b-th>
                    </b-tr>
                </template>
                <template #head(open)>
                    <button type="button" class="btn btn-secondary">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="false"></i>
                        <i class="icon icon-chevron-circle" v-else></i>
                    </button>
                </template>
                <template #head(test2)>
                    <b-form-select>
                        <option value="">asfsaf</option>
                        <option value="">asfsaf</option>
                        <option value="">asfsaf</option>
                    </b-form-select>
                </template>
                <template #cell(test8)="data">
                    <span class="text-danger bold">{{data.value}}</span>
                </template>
                <template #cell(test9)="data">
                    <span class="risk danger" v-if="data.value">Есть риск</span>
                    <span class="risk success" v-else>Нет риска</span>
                </template>
                <template #cell(more)="data">
                    <b-dropdown id="dropdown-dropleft" dropleft class="more">
                        <template v-slot:button-content>
                            <i class="icon icon-more"></i>
                        </template>
                        <template>
                            <b-dropdown-item @click="edit = !edit">
                                Добавить
                            </b-dropdown-item>
                            <b-dropdown-item @click="edit = !edit">
                                Редактировать
                            </b-dropdown-item>
                            <b-dropdown-item>
                                Удалить
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </template>
                <template #bottom-row>
                    <td :colspan="tableFields.length" class="p-0">
                        <span class="add-link-button ml-3"> <i class="icon icon-plus-circle add-button"></i> <span>Добавить</span></span>
                    </td>
                </template>
            </b-table>-->

            <div class="b-table-sticky-header table-responsive-true">
                <table class="table b-table table-bordered b-table-no-border-collapse">
                    <thead>
                    <tr class="text-center">
                        <th rowspan="2" class="toggle-show">
                            <button type="button" class="btn btn-secondary" @click="collapseAll = !collapseAll;">
                                <i class="icon icon-chevron-circle" v-if="collapseAll"></i>
                                <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                            </button>
                        </th>
                        <th rowspan="2">Целевые индикаторы</th>
                        <th rowspan="2">Ед. измерения</th>
                        <th>Факт {{ yearFact }}</th>
                        <th colspan="5">План</th>
                        <th rowspan="2">Процент достижения</th>
                        <th rowspan="2">Риск не достижения</th>
                        <th rowspan="2">Ответственные за исполнение</th>
                        <th rowspan="2"/>
                    </tr>
                    <tr class="text-center">
                        <th>
                            <b-form-group class="bolded no-line">
                                <b-form-select
                                    v-model="month"
                                    :options="optionsMonths"
                                    value-field="month"
                                    text-field="name"
                                    @change="getDataByMonth"
                                ></b-form-select>
                            </b-form-group>
                        </th>
                        <th>{{ yearOne }}</th>
                        <th>{{ yearTwo }}</th>
                        <th>{{ yearThree }}</th>
                        <th>{{ yearFour }}</th>
                        <th>{{ yearFive }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(targetItem, targetItemIndx) of forecastArr">
                        <tr :key="'targetItemIndx'+targetItemIndx" class="table-info">
                            <td class="toggle-show">
                                <template v-if="!targetItem.under_list_empty">
                                    <button type="button" class="btn btn-secondary" @click="collapseItems(targetItemIndx)">
                                        <i class="icon icon-chevron-circle" v-if="collapseArr[targetItemIndx]"></i>
                                        <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                    </button>
                                </template>
                            </td>
                            <td>{{ targetItem.indicator_ru}}</td>
                            <td>{{ targetItem.unit }}</td>
                            <td>{{ targetItem.fact }}</td>
                            <td>{{ targetItem.planOne.value }}</td>
                            <td>{{ targetItem.planTwo.value }}</td>
                            <td>{{ targetItem.planThree.value }}</td>
                            <td>{{ targetItem.planFour.value }}</td>
                            <td>{{ targetItem.planFive.value }}</td>
                            <td>
                                <span class="text-danger bold" v-if="targetItem.riskOfNotAchieving === true">{{ targetItem.achievementPercentage }}%</span>
                                <span class="text-success bold" v-else-if="targetItem.riskOfNotAchieving === false">{{ targetItem.achievementPercentage }}%</span>
                                <span v-else></span>
                            </td>
                            <template v-if="targetItem.riskOfNotAchieving === true">
                                <td style="background-color: #e06161; color: #ffffff;"><ul style="margin-left: -20px; margin-bottom: -5px;"><li>Есть риск</li></ul></td>
                            </template>
                            <template v-else-if="targetItem.riskOfNotAchieving === false">
                                <td style="background-color: #54b274; color: #ffffff;"><ul style="margin-left: -20px; margin-bottom: -5px;"><li>Нет риска</li></ul></td>
                            </template>
                            <template v-else-if="targetItem.riskOfNotAchieving === ''">
                                <td></td>
                            </template>
                            <td>
                                <template v-for="(el, index) of targetItem.responsible">
                                        {{ el }}
                                    <template v-if="index < targetItem.responsible.length-1">
                                        {{ ', ' }}
                                    </template>
                                </template>
                            </td>
                            <td>
                                <b-dropdown id="dropdown-dropleft" dropleft class="more">
                                    <template v-slot:button-content>
                                        <i class="icon icon-more"></i>
                                    </template>
                                    <template>
                                        <b-dropdown-item @click="editClk(targetItem)">
                                            Редактировать
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="decomposeClk(targetItem, targetItemIndx)">
                                            Декомпозировать
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="deleteClk(targetItem, targetItemIndx)">
                                            Удалить
                                        </b-dropdown-item>
                                    </template>
                                </b-dropdown>
                            </td>
                        </tr>
                        <template v-if="!collapseArr[targetItemIndx]">
                            <template v-for="(underItem, underItemIndex) of forecastArrUnder">
                                <template v-if="underItem!==null && underItem.parent_id === targetItem.forecast_id && underItem.indicator_id===targetItem.indicator_id && underItem.isOblast===false">
                                    <tr :key="`targetItemIndx_${targetItemIndx}_${underItemIndex}`">
                                        <td></td>
                                        <td>
                                            <multiselect
                                                v-model="underItem.dictOblRegVal"
                                                track-by="id"
                                                label="name"
                                                placeholder="Выберите регион"
                                                :options="oblRegArr"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                            >
                                                <template
                                                    slot="singleLabel"
                                                    slot-scope="{ option }"
                                                >
                                                    <template v-if="option.name!==undefined">
                                                        <span v-if="option.name.length<16">{{ option.name }}</span>
                                                        <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                                    </template>
                                                </template>
                                            </multiselect>
                                        </td>
                                        <td>{{ underItem.unit }}</td>
                                        <td><b-form-input type="text" v-model="underItem.fact"></b-form-input></td>
                                        <td><b-form-input type="text" v-model="underItem.planOne.value"></b-form-input></td>
                                        <td><b-form-input type="text" v-model="underItem.planTwo.value"></b-form-input></td>
                                        <td><b-form-input type="text" v-model="underItem.planThree.value"></b-form-input></td>
                                        <td><b-form-input type="text" v-model="underItem.planFour.value"></b-form-input></td>
                                        <td><b-form-input type="text" v-model="underItem.planFive.value"></b-form-input></td>
                                        <td>
                                            <span class="text-danger bold" v-if="underItem.riskOfNotAchieving === true">{{ underItem.achievementPercentage }} %</span>
                                            <span class="text-success bold" v-else-if="underItem.riskOfNotAchieving === false">{{ underItem.achievementPercentage }} %</span>
                                            <span v-else></span>
                                        </td>
                                        <template v-if="underItem.riskOfNotAchieving === true">
                                            <td style="background-color: #dc5c5c; color: #ffffff;">Есть риск</td>
                                        </template>
                                        <template v-else-if="underItem.riskOfNotAchieving === false">
                                            <td style="background-color: #54b274; color: #ffffff;">Нет риска</td>
                                        </template>
                                        <template v-else-if="underItem.riskOfNotAchieving === ''">
                                            <td></td>
                                        </template>
                                        <td>
                                            <multiselect
                                                v-model="underItem.responsible"
                                                :options="forecastEbkFuncArr"
                                                :multiple="true"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :preserve-search="true"
                                                placeholder="Выбрать исполнителя"
                                                label="name_ru"
                                                track-by="abp"
                                                :preselect-first="false">
                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                    <template v-if="values.length >= 0">
                                                        <span class="multiselect__single"
                                                              v-if="values.length && !isOpen">
                                                            <template v-for="(el) of underItem.responsible">
                                                                {{ el.name_ru+'; ' }}
                                                            </template>
                                                        </span>
                                                    </template>
                                                </template>
                                            </multiselect>
                                        </td>
                                        <td>
                                            <b-link @click="removeUnderForecastRow(underItem, underItemIndex)" class="icon icon-clear">Удалить</b-link>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                        <template v-if="targetItem.addBtn">
                            <tr :key="`targetItemIndx_${targetItemIndx}`">
                                <td></td>
                                <td colspan="11">
                                    <b-link @click="addRegionForecastRow(targetItem, targetItemIndx)" class="add-link">Добавить</b-link>
                                </td>
                            </tr>
                        </template>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-show="edit">
            <IndicatorsAdd :currentProgramPassport="currentProgramPassport" :currentForecast="currentForecast" :btnParams="btnParams" @closeEdit="closeEditForm"/>
        </div>
    </div>
</template>

<script>
    import IndicatorsAdd from "@/modules/development-program/tabs/IndicatorsAdd";
    import VueElementLoading from 'vue-element-loading';

    export default {
        name: 'indicators',
        components: {
            IndicatorsAdd,
            'loading': VueElementLoading
        },
        props: {
            headParamsQuery: {
                required: true,
                default: null
            },
            headParams: {
                required: true,
                default: null
            },
            currentProgramPassport: {
                required: true,
                default: null
            }
        },
        created() {
            this.getFilteredForecastData();
            this.currentYear = new Date().getFullYear();
            this.$watch('headParamsQuery', this.getFilteredForecastData);
            this.$watch('headParams', this.getFieldsOfTable);
            this.$watch('currentProgramPassport.programPassportId', this.getYearColumnNames);
            this.$watch('collapseAll', this.collapseAllFunc);
            this.getListOfForecastEbkFunc();
        },
        data() {
            return {
                loading: false,
                edit: false,
                isForecastRegion: false,
                forecastList: [],
                forecastArr: [],
                collapseAll: true,
                collapseArr: [],
                initArr: [],
                yearFromFilter: null,
                forecastArrCopy: [],
                currentForecast: null,
                btnParams: '',
                yearFact: '',
                yearOne: '',
                yearTwo: '',
                yearThree: '',
                yearFour: '',
                yearFive: '',
                forecastArrUnder: [],
                dictOblRegVal: null,
                oblRegArr: [],
                originInitArr: [],
                optionsMonths: [
                    {'name': 'Январь', 'month': 0},
                    {'name': 'Январь-Февраль', 'month': 1},
                    {'name': 'Январь-Март', 'month': 2},
                    {'name': 'Январь-Апрель', 'month': 3},
                    {'name': 'Январь-Май', 'month': 4},
                    {'name': 'Январь-Июнь', 'month': 5},
                    {'name': 'Январь-Июль', 'month': 6},
                    {'name': 'Январь-Август', 'month': 7},
                    {'name': 'Январь-Сентябрь', 'month': 8},
                    {'name': 'Январь-Октябрь', 'month': 9},
                    {'name': 'Январь-Ноябрь', 'month': 10},
                    {'name': 'Январь-Декабрь', 'month': 11}
                ],
                month: 0,
                executors: [],
                abbrOfExecutors: [],
                forecastEbkFuncArr: [],
                executorsUnder: []
            }
        },
        methods: {
            async closeEditForm() {
                this.edit = false;
                this.forecastArr = [];
                this.forecastList = [];
                this.forecastArrCopy = [];
                await this.getFilteredForecastData();
            },

            getYearColumnNames(){
                const currentProgramPassport = this.currentProgramPassport;
                const startDate = new Date(currentProgramPassport.startDate).getFullYear();
                const endDate = new Date(currentProgramPassport.endDate).getFullYear();

                this.yearOne = startDate;
                this.yearTwo = startDate+1;
                this.yearThree = startDate+2;
                this.yearFour = startDate+3;
                this.yearFive = endDate;
            },

            //----Выгрузка и построение основного списка в грид таблице
            async getFilteredForecastData() {
                this.loading=true;
                const headParamsQuery = this.headParamsQuery;
                this.forecastArr = [];   //---массив для справочника Целей
                this.forecastList = [];
                this.originInitArr = [];
                this.abbrOfExecutors = [];
                this.executors = [];
                this.initArr = [];
                this.collapseArr = [];
                let response = [];
                if (headParamsQuery.prgId !== null && headParamsQuery.year !== null && headParamsQuery.oblast) {
                    this.getYearColumnNames();
                    this.yearFact = headParamsQuery.year;
                    try {
                        response = await fetch('/api/stat-dicts/get-forecast?year=' + headParamsQuery.year + '&prgId=' + headParamsQuery.prgId + '&codeKato=' + headParamsQuery.oblast);
                        response = await response.json();
                        this.forecastArrCopy = response;
                        this.originInitArr = Object.assign([], response);
                        this.yearFromFilter = headParamsQuery.year;

                        //----Извлекаю массив Ответственных исполнителей и forecastId
                        const listOfForecastExecutors = getListOfExecutors(response, this.forecastEbkFuncArr);
                        this.abbrOfExecutors = getExecutorWithAbbr(listOfForecastExecutors); //----список исполнителей для массива forecastArr
                        this.executors = listOfForecastExecutors;   //----список исполнителей для массива forecastArrUnder
                        for (const arr of response) {
                            this.initArr.push(arr);
                            let direction = {};
                            let goals = {};
                            let indicator = {};
                            let sgpParentDoc = {};
                            let statKato = {};

                            // console.log('arr.forecast.direction: ' + JSON.stringify(arr.forecast.direction));
                            if (arr.forecast.direction!==null) {
                                direction = {
                                    id: arr.forecast.direction.id,
                                    name_ru: arr.forecast.direction.name_ru!==null && arr.forecast.direction.name_ru!=='' ? arr.forecast.direction.name_ru : '',
                                    name_en: arr.forecast.direction.name_en!==null && arr.forecast.direction.name_en!=='' ? arr.forecast.direction.name_en : '',
                                    name_kz: arr.forecast.direction.name_kz!==null && arr.forecast.direction.name_kz!=='' ? arr.forecast.direction.name_kz : '',
                                    goals_id: arr.forecast.goals!==null ? arr.forecast.goals.id : null,
                                    indicator_id: arr.forecast.indicator!==null ? arr.forecast.indicator.id : null,
                                    sgpParentDoc_id: arr.forecast.sgpParentDoc!==null ? arr.forecast.sgpParentDoc.id : null,
                                    statKato_id: arr.forecast.statKato!==null ? arr.forecast.statKato.id : null
                                }
                            }
                            if (arr.forecast.goals!==null) {
                                goals = {
                                    id: arr.forecast.goals.id,
                                    name_ru: arr.forecast.goals.name_ru!==null && arr.forecast.goals.name_ru!=='' ? arr.forecast.goals.name_ru : '',
                                    name_kz: arr.forecast.goals.name_kz!==null && arr.forecast.goals.name_kz!=='' ? arr.forecast.goals.name_kz : '',
                                    name_en: arr.forecast.goals.name_en!==null && arr.forecast.goals.name_en!=='' ? arr.forecast.goals.name_en : '',
                                    direction_id: arr.forecast.direction!==null ? arr.forecast.direction.id : null,
                                    indicator_id: arr.forecast.indicator!==null ? arr.forecast.indicator.id : null,
                                    sgpParentDoc_id: arr.forecast.sgpParentDoc!==null ? arr.forecast.sgpParentDoc.id : null,
                                    statKato_id: arr.forecast.statKato!==null ? arr.forecast.statKato.id : null
                                }
                            }
                            if (arr.forecast.indicator!==null) {
                                indicator = {
                                    id: arr.forecast.indicator.id,
                                    name_ru: arr.forecast.indicator.name_ru!==null && arr.forecast.indicator.name_ru!=='' ? arr.forecast.indicator.name_ru : '',
                                    name_kz: arr.forecast.indicator.name_kz!==null && arr.forecast.indicator.name_kz!=='' ? arr.forecast.indicator.name_kz : '',
                                    name_en: arr.forecast.indicator.name_en!==null && arr.forecast.indicator.name_en!=='' ? arr.forecast.indicator.name_en : '',
                                    direction_id: arr.forecast.direction!==null ? arr.forecast.direction.id : null,
                                    goals_id: arr.forecast.goals!==null ? arr.forecast.goals.id : null,
                                    sgpParentDoc_id: arr.forecast.sgpParentDoc!==null ? arr.forecast.sgpParentDoc.id : null,
                                    statKato_id: arr.forecast.statKato!==null ? arr.forecast.statKato.id : null
                                }
                            }
                            if (arr.forecast.sgpParentDoc!==null) {
                                sgpParentDoc = {
                                    id: arr.forecast.sgpParentDoc.id,
                                    name_ru: arr.forecast.sgpParentDoc.name_ru!==null && arr.forecast.sgpParentDoc.name_ru!=='' ? arr.forecast.sgpParentDoc.name_ru : '',
                                    name_kz: arr.forecast.sgpParentDoc.name_kz!==null && arr.forecast.sgpParentDoc.name_kz!=='' ? arr.forecast.sgpParentDoc.name_kz : '',
                                    name_en: arr.forecast.sgpParentDoc.name_en!==null && arr.forecast.sgpParentDoc.name_en!=='' ? arr.forecast.sgpParentDoc.name_en : '',
                                    direction_id: arr.forecast.direction!==null ? arr.forecast.direction.id : null,
                                    goals_id: arr.forecast.goals!==null ? arr.forecast.goals.id : null,
                                    indicator_id: arr.forecast.indicator!==null ? arr.forecast.indicator.id : null,
                                    statKato_id: arr.forecast.statKato!==null ? arr.forecast.statKato.id : null
                                }
                            }
                            if (arr.forecast.statKato!==null) {
                                statKato = {
                                    id: arr.forecast.statKato.id,
                                    parent: arr.forecast.statKato.parent!==null ? arr.forecast.statKato.parent : '',
                                    code: arr.forecast.statKato.code!==null && arr.forecast.statKato.code!=='' ? arr.forecast.statKato.code : '',
                                    name_ru: arr.forecast.statKato.name_ru!==null && arr.forecast.statKato.name_ru!=='' ? arr.forecast.statKato.name_ru : '',
                                    name_kz: arr.forecast.statKato.name_kz!==null && arr.forecast.statKato.name_kz!=='' ? arr.forecast.statKato.name_kz : '',
                                    direction_id: arr.forecast.direction!==null ? arr.forecast.direction.id : null,
                                    goals_id: arr.forecast.goals!==null ? arr.forecast.goals.id : null,
                                    indicator_id: arr.forecast.indicator!==null ? arr.forecast.indicator.id : null,
                                    sgpParentDoc_id: arr.forecast.sgpParentDoc!==null ? arr.forecast.sgpParentDoc.id : null
                                }
                            }
                            const arrObjs = {
                                direction: direction,
                                goals: goals,
                                indicator: indicator,
                                sgpParentDoc: sgpParentDoc,
                                statKato: statKato,
                                isOblast: arr.katoStatObl.isOblast,
                                parent_id: arr.parent_id
                            }
                            // console.log('arrObjs: ' + JSON.stringify(arrObjs));
                            this.forecastList.push(arrObjs);
                            const forecastTbl = this.structuredForecastTable(arr);
                            if (forecastTbl.isOblast === true)
                            {
                                this.collapseArr.push(true);
                                this.forecastArr.push(forecastTbl);
                            }
                        }
                        await this.loadOblReg(headParamsQuery.oblast);
                        this.getFilteredForecastRegionsData(response);
                    } catch (error) {
                        this.makeToast('Information', 'таблица forecast пуста', error.toString());
                    }

                    this.getChosenOrCurrentMonth(headParamsQuery.year); //----Extracting the current or chosen in filter month
                    this.$emit('listOfForecast', this.forecastList);
                }
                this.loading=false;

                //----Извлекаю массив Ответственных исполнителей и forecastId
                function getListOfExecutors(response, forecastEbkFuncArr){
                    const execArr = [];
                    if (response.length>0){
                        for (const forecastEls of response){
                            if (forecastEls.forecast.executors.length>0) {
                                const executorsEl = getExecutors(forecastEls.forecast.executors, forecastEbkFuncArr);
                                const exeObj = {
                                    'forecast_id': forecastEls.forecast.id,
                                    'executors': executorsEl
                                }
                                execArr.push(exeObj);
                            }
                        }
                    }
                    return execArr;
                }
                function getExecutors(executors, executorsList){
                    // console.log('executorsList: ' + JSON.stringify(executorsList));
                    // console.log('executors: ' + JSON.stringify(executors));
                    const executorArr = [];
                    if (executors.length > 0) {
                        for (const el of executorsList) {
                            for (const executor of executors){
                                if (el.abp === executor.dict_ebk_func_abp){
                                    const abpObj = {
                                        "abp": el.abp,
                                        "name_ru": el.name_ru,
                                        "name_kk": el.name_kk
                                        // ,
                                        // "short_name_ru": el.short_name_ru,
                                        // "short_name_kk": el.short_name_kk
                                    }
                                    executorArr.push(abpObj);
                                    break;
                                }
                            }
                        }
                    }
                    return executorArr;
                }
                //----список исполнителей для массива forecastArr
                function getExecutorWithAbbr(listOfForecastExecutors){
                    const arr = [];
                    if (listOfForecastExecutors.length > 0){
                        for (const execFull of listOfForecastExecutors) {
                            const abbrObj = {
                                'forecast_id': execFull.forecast_id,
                                'execAbbr': arrOfExecutors(execFull.executors)
                            }
                            arr.push(abbrObj);
                        }
                    }
                    return arr;
                }
                //---Получить список аббревиатур ответственных исполнителей в одном подмассиве
                function arrOfExecutors(executors){
                    const abbrList = [];
                    if (executors.length > 0){
                        for (const exec of executors){
                            const abrrs = getAbbrOfWords(exec.name_ru);
                            if (abrrs.length > 0) {
                                abbrList.push(abrrs);
                            }
                        }
                    }
                    return abbrList;
                }
                //---Получить аббревиатуры слов
                function getAbbrOfWords(words){
                    let joinedLetters = '';
                    if (words.length > 0) {
                        const arrAbbr = [];
                        const arr = words.split(' ').join(',');
                        const arrWithoutGaps = arr.trim();
                        const mainArr = arrWithoutGaps.split('–').join(',').split(',');
                        for (const el of mainArr) {
                            arrAbbr.push(el.slice(0, 1));
                        }
                        joinedLetters = arrAbbr.join('');
                    }
                    return joinedLetters.toUpperCase();
                }
            },
            //----Получить массив с объектами ответственных исполнителей для главного уровня
            getExecutorsByForecastId(forecast_id){
              if (this.abbrOfExecutors.length > 0) {
                  for (const abbr of this.abbrOfExecutors) {
                      if (abbr.forecast_id == forecast_id) {
                          return abbr.execAbbr;
                      }
                  }
              }
              return [];
            },

            async getListOfForecastEbkFunc() {
                let response = [];
                try {
                    response = await fetch('/api/forecast-prg-dev/abps');
                    response = await response.json();
                    // console.log('response: ' + JSON.stringify(response));
                    this.forecastEbkFuncArr = response;
                } catch (error) {
                    this.makeToast('Information', 'таблица forecastEbkFunc пуста', error.toString());
                }
            },

            //----Extract the current or chosen in filter month
            getChosenOrCurrentMonth(year){
                const currentSystemYear = new Date().getFullYear();
                const currentSystemMonth = new Date().getMonth();
                if (currentSystemYear > year){
                    this.month = 11;
                    this.getDataByMonth(this.month);
                } else if (currentSystemYear < year){
                    this.month = 0;
                    this.getDataByMonth(this.month);
                } else {
                    this.month = currentSystemMonth;
                    this.getDataByMonth(this.month);
                }
            },

            //----Выгрузка и построение вложенного списка в грид таблице
            getFilteredForecastRegionsData(response){
                if (response.length > 0){
                    this.forecastArrUnder = [];
                    this.collapseArr = [];
                    for (const arr of response) {
                        for (const kato of this.oblRegArr) {
                            if (arr.forecast.statKato.id === kato.id && arr.katoStatObl.isOblast === false && arr.parent_id !==0){
                                arr.forecast.kato = kato;
                                this.forecastArrUnder.push(this.structuredForecastTableForEditing(arr));
                                this.collapseArr.push(true);    // TODO: Нужно сделать так чтобы последний вложенный список тоже закрывался
                            }
                        }
                    }
                }
                // console.log("forecastArrUnder: " + JSON.stringify(this.forecastArrUnder));
            },

            //-----Фильтрация в грид таблице
            async getFieldsOfTable(headParams) {
                this.forecastArr = [];
                if (this.initArr.length > 0) {
                    let initArrTemp = this.initArr;
                    for (const params in headParams) {
                        if (headParams[params].id !== "") {
                            // console.log('params: ' + JSON.stringify(params) + 'headParams[params]: ' + JSON.stringify(headParams[params]));
                            const filterParamValue = getHeadParams(params, headParams); //---Извлекается значение параметра для фильтра по названию параметра фильтра

                            if (filterParamValue!=null && params==='direction'){
                                initArrTemp = getForecastByDirection(headParams[params].id, initArrTemp);
                            } else if (filterParamValue!=null && params==='target'){
                                initArrTemp = getForecastByGoals(headParams[params].id, initArrTemp);
                            } else if (filterParamValue!=null && params==='targetIndicator'){
                                initArrTemp = getForecastByIndicator(headParams[params].id, initArrTemp);
                            } else if (filterParamValue!=null && params==='sgpProgram'){
                                initArrTemp = getForecastBySgpProgram(headParams[params].id, initArrTemp);
                            } else if (filterParamValue!=null && params==='codeKato'){
                                initArrTemp = getForecastByCodeKato(headParams[params].id, this.initArr);
                            }

                        } else {
                            initArrTemp = getForecastWithOblast(initArrTemp);
                        }
                    }
                    this.forecastArr = this.getForecastForGridTable(initArrTemp);
                    this.getChosenOrCurrentMonth(this.headParamsQuery.year); //----Extracting the current or chosen in filter month
                }

                function getHeadParams(params, headParams){
                    switch (params) {
                        case 'direction':
                            return headParams[params].id;
                        case 'target':
                            return headParams[params].id;
                        case 'targetIndicator':
                            return headParams[params].id;
                        case 'sgpProgram':
                            return headParams[params].id;
                        case 'codeKato':
                            return headParams[params].id;
                        default:
                            return null
                    }
                } //----Выдает параметр фильтра и его значение

                function getForecastByDirection(value, initArr){
                    const forecastArr = [];
                    for (const forecast of initArr) {
                        if (forecast.forecast.direction !== null && forecast.forecast.direction.id === value && forecast.katoStatObl.isOblast === true) {
                            forecastArr.push(forecast);
                        }
                    }
                    // console.log('forecastArr: ' + JSON.stringify(forecastArr));
                    return forecastArr;
                }

                function getForecastByGoals(value, initArr){
                    const forecastArr = [];
                    for (const forecast of initArr) {
                        if (forecast.forecast.goals !== null && forecast.forecast.goals.id === value && forecast.katoStatObl.isOblast === true) {
                            forecastArr.push(forecast);
                        }
                    }
                    return forecastArr;
                }

                function getForecastByIndicator(value, initArr){
                    const forecastArr = [];
                    for (const forecast of initArr) {
                        if (forecast.forecast.indicator !== null && forecast.forecast.indicator.id === value && forecast.katoStatObl.isOblast === true) {
                            forecastArr.push(forecast);
                        }
                    }
                    return forecastArr;
                }

                function getForecastBySgpProgram(value, initArr){
                    const forecastArr = [];
                    for (const forecast of initArr){
                        if (forecast.forecast.sgpParentDoc!==null && forecast.forecast.sgpParentDoc.id === value && forecast.katoStatObl.isOblast === true) {
                            forecastArr.push(forecast);
                        }
                    }
                    return forecastArr;
                }

                //---Первично получаю все объекты по фильтру региона, затем по ним ищу родительский объект в getOblastByRegion(initArr, parentId)
                function getForecastByCodeKato(value, initArr){     //--фильтрация по регионам
                    // console.log('value: ' + JSON.stringify(value));
                    const forecastArr = [];
                    for (const forecast of initArr){
                        if (forecast.forecast.statKato!==null && forecast.forecast.statKato.id === value) {
                            // console.log('forecast.parent_id: '+forecast.parent_id+' forecast: ' + JSON.stringify(forecast));
                            const forecastObls = getOblastByRegion(initArr, forecast);
                            // console.log('forecastObls: ' + JSON.stringify(forecastObls));
                            if (JSON.stringify(forecastObls) !== "{}"){
                                forecastArr.push(forecastObls);
                            }
                        }
                    }
                    return forecastArr;
                }

                //---Получаю родительский объект в который был вложен регион-->
                function getOblastByRegion(initArr, forecast){
                    if (initArr.length > 0 && forecast){
                        if (forecast.katoStatObl.isOblast === false) {
                            for (const arr of initArr) {
                                if (arr.forecast.id === forecast.parent_id) {
                                    return arr;
                                }
                            }
                        } else {
                            for (const arr of initArr) {
                                if (arr.forecast.id === forecast.forecast.id) {
                                    return arr;
                                }
                            }
                        }
                    }
                    return {};
                }

                function getForecastWithOblast(initArr){
                    const forecastArr = [];
                    for (const forecast of initArr) {
                        if (forecast.katoStatObl.isOblast === true) {
                            forecastArr.push(forecast);
                        }
                    }
                    return forecastArr;
                }
            },

            getForecastForGridTable(initArr){
                const forecastArr = [];
                for (const forecast of initArr) {
                    forecastArr.push(this.structuredForecastTable(forecast));
                }
                return forecastArr;
            },

            structuredForecastTable(arr){
                // console.log('arr: ' + JSON.stringify(arr));
                let forecastObj = {};
                if (Object.keys(arr).length !== 0){
                    let katoObl = '';
                    // console.log('arr.katoStatObl: ' + JSON.stringify(arr.katoStatObl));
                    if (arr.katoStatObl.isOblast===true) {
                        katoObl = arr.katoStatObl.dictStatKatoEntity.code;
                        // console.log('katoObl: ' + JSON.stringify(katoObl));
                    }
                    const yearObj = this.getFactByYear(arr.forecast.years, this.yearFact, this.month);
                    // console.log('yearObj: ' + JSON.stringify(yearObj));
                    let fact = '';
                    let plan = '';
                    let month = '';
                    if (JSON.stringify(yearObj) !== "{}" && katoObl===arr.forecast.statKato.code) {
                        // console.log('forecast_id: ' + arr.forecast.id + ' yearObj: ' + JSON.stringify(yearObj));
                        fact = yearObj.fact;
                        plan = yearObj.plan;
                        month = yearObj.month;
                    }
                    forecastObj = {
                        'parent_id': '',
                        'forecast_id': arr.forecast.id,
                        'dictOblRegVal': arr.forecast.statKato.id,
                        'indicator_id': arr.forecast.indicator.id,
                        'indicator_ru': arr.forecast.indicator.name_ru,
                        'indicator_kz': arr.forecast.indicator.name_kz,
                        'indicator_en': arr.forecast.indicator.name_en,
                        'unit': arr.forecast.statUnit.nationalSymbol!==null ? arr.forecast.statUnit.nationalSymbol : arr.forecast.statUnit.name_ru,
                        'fact': fact,
                        'planOne': {
                            'year': this.yearOne,
                            'month': month,
                            'value': arr.forecast.years.find(({year}) => year === this.yearOne) != null ? arr.forecast.years.find(({year}) => year === this.yearOne).plan : ''
                        },
                        'planTwo': {
                            'year': this.yearTwo,
                            'month': month,
                            'value': arr.forecast.years.find(({ year }) => year === this.yearTwo)!=null ? arr.forecast.years.find(({ year }) => year === this.yearTwo).plan : ''
                        },
                        'planThree': {
                            'year': this.yearThree,
                            'month': month,
                            'value': arr.forecast.years.find(({ year }) => year === this.yearThree)!=null ? arr.forecast.years.find(({ year }) => year === this.yearThree).plan : ''
                        },
                        'planFour': {
                            'year': this.yearFour,
                            'month': month,
                            'value': arr.forecast.years.find(({ year }) => year === this.yearFour)!=null ? arr.forecast.years.find(({ year }) => year === this.yearFour).plan: ''
                        },
                        'planFive': {
                            'year': this.yearFive,
                            'month': month,
                            'value': arr.forecast.years.find(({ year }) => year === this.yearFive)!=null ? arr.forecast.years.find(({ year }) => year === this.yearFive).plan: ''
                        },
                        'achievementPercentage': '',
                        'riskOfNotAchieving': '',
                        'responsible': this.getExecutorsByForecastId(arr.forecast.id),
                        'isOblast': arr.katoStatObl.isOblast,
                        'addBtn': false,
                        'years': [],
                        'under_list_empty': arr.under_list_empty
                    }
                    // console.log('forecastObj: ' + JSON.stringify(forecastObj));
                }
                return forecastObj;
            },

            getFactByYear(yearArr, curYear, month){
                if (yearArr.length>0){
                    for (const item of yearArr){
                        if (item.year===curYear && item.month===month){
                            return {
                                'year': item.year,
                                'plan': item.plan,
                                'fact': item.factOld,
                                'month': item.month,
                                'frequency': item.frequency
                            }
                            break;
                        }
                    }
                }
                return {};
            },

            structuredForecastTableForEditing(arr){
                let forecastObj = {};
                if (Object.keys(arr).length !== 0){
                    let katoObl = '';
                    if (arr.katoStatObl.isOblast===false) {
                        katoObl = arr.katoStatObl.dictStatKatoEntity.code;
                    }
                    const yearObj = this.getFactByYear(arr.forecast.years, this.yearFact, this.month);
                    let fact = '';
                    let plan = '';
                    let month = '';
                    if (JSON.stringify(yearObj) !== "{}" && katoObl===arr.forecast.statKato.code) {
                        // console.log('forecast_id: ' + arr.forecast.id + ' yearObj: ' + JSON.stringify(yearObj));
                        fact = yearObj;
                        plan = yearObj.plan;
                        month = yearObj.month;
                    }
                    forecastObj = {
                        'parent_id': arr.parent_id,
                        'forecast_id': arr.forecast.id,
                        'dictOblRegVal': arr.forecast.kato,
                        'indicator_id': arr.forecast.indicator.id,
                        'indicator_ru': arr.forecast.indicator.name_ru,
                        'indicator_kz': arr.forecast.indicator.name_kz,
                        'indicator_en': arr.forecast.indicator.name_en,
                        'unit': arr.forecast.statUnit.nationalSymbol!==null ? arr.forecast.statUnit.nationalSymbol : arr.forecast.statUnit.name_ru,
                        'fact': arr.forecast.years.find(({ year }) => year === this.yearFact)!=null && arr.forecast.years.find(({ year }) => year === this.yearFact).factOld!=null ? arr.forecast.years.find(({ year }) => year === this.yearFact).factOld : '',
                        'planOne': {
                            'year': this.yearOne,
                            'value': arr.forecast.years.find(({ year }) => year === this.yearOne) != null ? arr.forecast.years.find(({ year }) => year === this.yearOne).plan : ''
                        },
                        'planTwo': {
                            'year': this.yearTwo,
                            'value': arr.forecast.years.find(({ year }) => year === this.yearTwo)!=null ? arr.forecast.years.find(({ year }) => year === this.yearTwo).plan : ''
                        },
                        'planThree': {
                            'year': this.yearThree,
                            'value': arr.forecast.years.find(({ year }) => year === this.yearThree)!=null ? arr.forecast.years.find(({ year }) => year === this.yearThree).plan : ''
                        },
                        'planFour': {
                            'year': this.yearFour,
                            'value': arr.forecast.years.find(({ year }) => year === this.yearFour)!=null ? arr.forecast.years.find(({ year }) => year === this.yearFour).plan: ''
                        },
                        'planFive': {
                            'year': this.yearFive,
                            'value': arr.forecast.years.find(({ year }) => year === this.yearFive)!=null ? arr.forecast.years.find(({ year }) => year === this.yearFive).plan: ''
                        },
                        'achievementPercentage': '',
                        'riskOfNotAchieving': '',
                        'responsible': this.getUnderExecutorsByForecastId(arr.forecast.id),
                        'isOblast': arr.katoStatObl.isOblast,
                        'addBtn': false,
                        'years': [],
                        'under_list_empty': arr.under_list_empty
                    }
                    // console.log('forecastObj: ' + JSON.stringify(forecastObj));
                }
                return forecastObj;
            },

            //----Получить массив объектов ответственных исполнителей для подуровня (второй уровень)
            getUnderExecutorsByForecastId(forecast_id){
                if (this.executors.length > 0){
                    for (const exec of this.executors){
                        if (exec.forecast_id === forecast_id){
                            return exec.executors;
                        }
                    }
                }
                return [];
            },

            addClk(){
                this.btnParams = 'add';
                this.currentForecast = {};
                this.getYearColumnNames();
                this.edit = true;
            },

            addRegionForecastRow(targetItem, targetItemIndx){
                for (const forecast of this.forecastArrCopy) {
                    if (targetItem.forecast_id === forecast.forecast.id) {
                        // console.log('forecast: ' + JSON.stringify(forecast));
                        const oblKatoId = forecast.forecast.statKato.code;
                        this.loadOblReg(oblKatoId);
                        const forecastObj = {
                            'parent_id': targetItem.forecast_id,
                            'forecast_id': '',
                            'dictOblRegVal': forecast.forecast.statKato.id,
                            'indicator_id': forecast.forecast.indicator.id,
                            'unit': forecast.forecast.statUnit.nationalSymbol!==null ? forecast.forecast.statUnit.nationalSymbol : forecast.forecast.statUnit.name_ru,
                            'fact': '',
                            'planOne': {
                                'year': this.yearOne,
                                'value': ''
                            },
                            'planTwo': {
                                'year': this.yearTwo,
                                'value': ''
                            },
                            'planThree': {
                                'year': this.yearThree,
                                'value': ''
                            },
                            'planFour': {
                                'year': this.yearFour,
                                'value': ''
                            },
                            'planFive': {
                                'year': this.yearFive,
                                'value': ''
                            },
                            'achievementPercentage': '',
                            'riskOfNotAchieving': '',
                            'responsible': [],
                            'isOblast': false
                        }
                        this.forecastArrUnder.push(forecastObj);
                    }
                }
                // console.log('forecastArrUnder: ' + JSON.stringify(this.forecastArrUnder));
            },

            addForecastRegionsToDB(){
                if (this.forecastArrCopy.length > 0 && this.forecastArrUnder.length > 0){
                    this.loading=true;
                    const commonArr = [];
                    const forecastUnder = this.forecastArrUnder;
                    for (const forecastMain of this.forecastArrCopy){
                        for (const arrUnder of forecastUnder) {
                            if (forecastMain.forecast.indicator.id === arrUnder.indicator_id && forecastMain.forecast.id === arrUnder.parent_id) {
                                // console.log('forecastMain.forecast: ' + JSON.stringify(forecastMain.forecast));
                                // console.log('arrUnder: ' + JSON.stringify(arrUnder.responsible));
                                const requiredArrForExecutors = changeObjArrExecutors(arrUnder);
                                const forecastNewObj = {
                                    'id': arrUnder.forecast_id !== "" && arrUnder.forecast_id !== null ? arrUnder.forecast_id : null,
                                    'indicator': forecastMain.forecast.indicator,
                                    'indicatorCustomName': forecastMain.forecast.indicatorCustomName,
                                    'indicatorCustomNameKz': forecastMain.forecast.indicatorCustomNameKz,
                                    'indicatorCustomNameEn': forecastMain.forecast.indicatorCustomNameEn,
                                    'program': forecastMain.forecast.program,
                                    'direction': forecastMain.forecast.direction,
                                    'sphere': forecastMain.forecast.sphere,
                                    'goals': forecastMain.forecast.goals,
                                    'statKato': Object.assign({}, arrUnder.dictOblRegVal),
                                    'statUnit': forecastMain.forecast.statUnit,
                                    'years': Object.assign([], this.getForecastUnderParams(arrUnder, this.yearOne, this.yearTwo, this.yearThree, this.yearFour, this.yearFive)),
                                    'abps': forecastMain.forecast.abps,
                                    'statSetting': forecastMain.forecast.statSetting,
                                    'coefficient': forecastMain.forecast.coefficient,
                                    'arithmetic_operation': forecastMain.forecast.arithmetic_operation,
                                    'executors': requiredArrForExecutors,
                                    'executor': forecastMain.forecast.executor,
                                    'smData': forecastMain.forecast.smData,
                                    'sgpParentDoc': forecastMain.forecast.sgpParentDoc,
                                    'macroindicator': forecastMain.forecast.macroindicator
                                }
                                const fullForecastNewObj = {
                                    'forecast': forecastNewObj,
                                    'katoStatObl': forecastMain.katoStatObl,
                                    'parent_id': forecastMain.forecast.id,
                                    'under_list_empty': forecastMain.under_list_empty
                                }
                                commonArr.push(fullForecastNewObj);
                            }

                        }

                    }
                    // console.log('commonArr: ' + JSON.stringify(commonArr))
                    this.saveRegionsWithYearsToDB(commonArr);
                }

                function changeObjArrExecutors(forecastArrWithExecutors){
                    const arr = [];
                    if (forecastArrWithExecutors!==null && forecastArrWithExecutors.responsible.length > 0){
                        for (const exec of forecastArrWithExecutors.responsible){
                            const execObj = {
                                'id': '',
                                'forecast': '',
                                'dict_ebk_func_abp': exec.abp
                            }
                            arr.push(execObj);
                            // ForecastExecEntity(id=null, forecast=null, dict_ebk_func_abp=0)
                        }
                    }
                    return arr;
                }
            },

            //-----------Получение массива с годами и значениями по каждому району
            getForecastUnderParams(underArr, yearOne, yearTwo, yearThree, yearFour, yearFive){
                const listOfYears = [];
                const tempArrYears = [];
                let yearObj = {};
                tempArrYears[0] = {'item': 'planOne', 'value': yearOne};
                tempArrYears[1] = {'item': 'planTwo', 'value': yearTwo};
                tempArrYears[2] = {'item': 'planThree', 'value': yearThree};
                tempArrYears[3] = {'item': 'planFour', 'value': yearFour};
                tempArrYears[4] = {'item': 'planFive', 'value': yearFive};
                for (const arr of tempArrYears){
                    if (arr.value === this.yearFact){
                        yearObj = arr;
                    }
                }

                const under = JSON.parse(JSON.stringify(underArr));
                // console.log('under: ' + JSON.stringify(under));
                // console.log('yearObj.item: ' + JSON.stringify(yearObj.item));
                for (const key in under) {
                    if (under.hasOwnProperty(key)){
                        if (key==="planOne") {
                            // console.log('under[key]: ' + under[key] + ' under[key].value: ' + under[key].value);
                            const years = {
                                "year": yearOne,
                                "plan": (key === "planOne" ? under[key].value : null),
                                "factOld": null,
                                "month": 12,
                                "frequency": null,
                                "fact": null,
                                "risk": null,
                                "startMonth": null,
                                "endMonth": null,
                                "dvalue": null
                            };
                            listOfYears.push(years);
                            if (JSON.stringify(key)===JSON.stringify(yearObj.item)){
                                console.log('planOne_(JSON.stringify(key)===JSON.stringify(yearObj.item)): ' + (JSON.stringify(key)===JSON.stringify(yearObj.item)));
                                getFactItems(listOfYears, under, yearObj);
                            }
                        }
                        else if (key==="planTwo") {
                            const years = {
                                "year": yearTwo,
                                "plan": (key === "planTwo" ? under[key].value : null),
                                "factOld": null,
                                "month": 12,
                                "frequency": null,
                                "fact": null,
                                "risk": null,
                                "startMonth": null,
                                "endMonth": null,
                                "dvalue": null
                            };
                            listOfYears.push(years);
                            if (JSON.stringify(key)===JSON.stringify(yearObj.item)) {
                                // console.log('planTwo_(JSON.stringify(key)===JSON.stringify(yearObj.item)): ' + (JSON.stringify(key) === JSON.stringify(yearObj.item)));
                                getFactItems(listOfYears, under, yearObj);
                            }
                        }
                        else if (key==="planThree") {
                            const years = {
                                "year": yearThree,
                                "plan": (key === "planThree" ? under[key].value : null),
                                "factOld": null,
                                "month": 12,
                                "frequency": null,
                                "fact": null,
                                "risk": null,
                                "startMonth": null,
                                "endMonth": null,
                                "dvalue": null
                            };
                            listOfYears.push(years);
                            if (JSON.stringify(key)===JSON.stringify(yearObj.item)) {
                                // console.log('planThree_(JSON.stringify(key)===JSON.stringify(yearObj.item)): ' + (JSON.stringify(key) === JSON.stringify(yearObj.item)));
                                getFactItems(listOfYears, under, yearObj);
                            }
                        }
                        else if (key==="planFour") {
                            const years = {
                                "year": yearFour,
                                "plan": (key === "planFour" ? under[key].value : null),
                                "factOld": null,
                                "month": 12,
                                "frequency": null,
                                "fact": null,
                                "risk": null,
                                "startMonth": null,
                                "endMonth": null,
                                "dvalue": null
                            };
                            listOfYears.push(years);
                            if (JSON.stringify(key)===JSON.stringify(yearObj.item)) {
                                // console.log('planFour_(JSON.stringify(key)===JSON.stringify(yearObj.item)): ' + (JSON.stringify(key) === JSON.stringify(yearObj.item)));
                                getFactItems(listOfYears, under, yearObj);
                            }
                        }
                        else if (key==="planFive") {
                            const years = {
                                "year": yearFive,
                                "plan": (key === "planFive" ? under[key].value : null),
                                "factOld": null,
                                "month": 12,
                                "frequency": null,
                                "fact": null,
                                "risk": null,
                                "startMonth": null,
                                "endMonth": null,
                                "dvalue": null
                            };
                            listOfYears.push(years);
                            if (JSON.stringify(key)===JSON.stringify(yearObj.item)) {
                                // console.log('planFive_(JSON.stringify(key)===JSON.stringify(yearObj.item)): ' + (JSON.stringify(key) === JSON.stringify(yearObj.item)));
                                getFactItems(listOfYears, under, yearObj);
                            }
                        }
                    }
                }
                //---заменяет значение элемента fact для конкретного года
                function getFactItems(listOfYears, under, yearObj){
                    for (const arr of listOfYears){
                        if (arr.year === yearObj.value) {
                            arr.factOld = under['fact'];
                        }
                    }
                }
                return listOfYears;
            },

            async saveRegionsWithYearsToDB(commonArr) {
                if (commonArr.length > 0) {
                    // console.log('commonArr: ' + JSON.stringify(commonArr));
                    let result = await fetch('/api/forecast-prg-dev/regions',
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            method: 'POST',
                            body: JSON.stringify(commonArr)
                        });
                    if (result.status === 200) {
                        result = await result.json();
                        // console.log('result: ' + JSON.stringify(result));
                        if (result === true) {
                            await this.getFilteredForecastData();
                            this.loading=false;
                        }
                        this.makeToast('success', 'Сохранение', 'Данные сохранены!');
                    } else {
                        this.makeToast('danger', 'Сохранение', `Ошибка ${result.status} ${result.statusText}`);
                    }
                }
            },

            removeUnderForecastRow(underItem, underItemIndex){
                this.forecastArrUnder.splice(underItemIndex, 1);
            },

            //----Извлекается справочник по области и по районам области пользователя----//
            async loadOblReg(oblastId) {
                let response = [];
                const result = [];
                this.oblRegArr = [];
                try {
                    response = await fetch('/api/stat-dicts/only-user-regions/'+oblastId);
                    response = await response.json();
                } catch (error) {
                    response = [];
                    this.makeToast('danger', 'Ошибка загрузки Области и районов пользователя', error.toString());
                }
                if (response.length > 0) {
                    // result.push(this.setNameLangs(this.all, 'regObl'));
                    for (const el of response) {
                        result.push(this.setNameLangs(el, 'regObl'))
                    }
                }
                if (this.dictOblRegVal !== null) {
                    // this.dictOblRegVal = this.setNameLangs(this.all, 'regObl');
                    this.dictOblRegVal = this.setNameLangs(this.dictOblRegVal, 'regObl');
                } else {
                    if (result.length > 0) {
                        this.dictOblRegVal = result[0];
                    }
                }
                this.oblRegArr = result;
            },

            editClk(targetItem){
                if (targetItem!==null){
                    this.btnParams = 'edit';
                    let itemObject = {};
                    for (const forecast of this.forecastArrCopy) {
                        if (targetItem.forecast_id === forecast.forecast.id) {
                            itemObject = forecast;
                        }
                    }
                    this.currentForecast = itemObject;
                    this.edit = true;
                }
            },

            async deleteClk(targetItem, targetItemIndx) {
                if (this.forecastArr.length > 0 && this.forecastArrUnder.length > 0){
                    const mainForecastElement = this.forecastArr[targetItemIndx];
                    const id = mainForecastElement.forecast_id;
                    try {
                        let result = await fetch(`/api/forecast-prg-dev/delete/${id}`, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            method: 'POST'
                        });
                        if (result.status === 200) {
                            result = await result.json();
                            console.log('result: ' + JSON.stringify(result));
                            if (result === true) {
                                this.makeToast('success', 'Удаление', 'Запись удалена');
                                await this.getFilteredForecastData();
                            } else {
                                this.makeToast('danger', 'Удаление', `Ошибка ${result.data}`);
                                return;
                            }
                        }
                    } catch (error) {
                        this.makeToast('danger', 'Ошибка удаления', error.toString());
                    }
                }
            },

            decomposeClk(targetItem, targetItemIndx){
                this.forecastArr[targetItemIndx].addBtn = true;
                this.collapseItems(targetItemIndx);
            },

            collapseItems(targetItemIndx) {  // ---------Раскрытие второго уровня при нажатии на стрелку вниз
                const rowIndex = targetItemIndx;
                // console.log('rowIndex: ' + rowIndex);
                this.collapseArr[rowIndex] = !this.collapseArr[rowIndex];
                // console.log('this.collapseArr[rowIndex]: ' + this.collapseArr[rowIndex]);
                this.collapseArr.push(false);
                this.collapseArr.splice(this.collapseArr.length - 1, 1);
            },

            collapseAllFunc() {
                for (let i = 0; i < this.collapseArr.length; i++) {
                    this.collapseArr[i] = this.collapseAll;
                    // console.log('this.collapseArr[i]: ' + JSON.stringify(this.collapseArr[i]));
                }
                this.collapseArr.push(false);
                this.collapseArr.splice(this.collapseArr.length - 1, 1);
                // -------------------------------------------------------------------//
            },

            //----Выборка фактических показателей по месяцам и году из фильтра
            getDataByMonth(month){
                if (month!=='') {
                    const factByMonthsArr = [];
                    if (this.forecastArrCopy.length > 0){
                        for (const el of this.forecastArrCopy){
                            if (el.forecast.years !== undefined && el.forecast.years.length > 0) {
                                const forecastWithMonth = getObjWithMonth(el.forecast.years);
                                if (forecastWithMonth !== null) {
                                    const yearsAndMonthsList = listOfYears(el.forecast.years, this.yearFact, month);
                                    if (yearsAndMonthsList.length > 0) {
                                        const monthNewObj = {
                                            'forecast_id': el.forecast.id,
                                            'indicator_id': el.forecast.indicator.id,
                                            'init_fact': el.forecast.years.find(({year}) => year === this.yearFact) != null && el.forecast.years.find(({year}) => year === this.yearFact).factOld != null ? el.forecast.years.find(({year}) => year === this.yearFact).factOld : '',
                                            'achievementPercentage': '',
                                            'riskOfNotAchieving': ''
                                        }

                                        factByMonthsArr.push(Object.assign(monthNewObj, listOfYearsAndMonths(el.forecast.years, this.yearFact, month)));
                                    }
                                }
                            }
                        }
                    }
                    // console.log('factByMonthsArr: ' + JSON.stringify(factByMonthsArr));
                    this.setFactValuesByMonths(factByMonthsArr, month);
                }

                function getObjWithMonth(monthArr){
                    if (monthArr.length > 0){
                        for (const el of monthArr){
                            if (el.month!==null){
                                return monthArr;
                                break;
                            }
                        }
                    }
                    return null;
                }

                function listOfYears(yearsArr, chosenYear, month){
                    const yearObjArr = [];
                    if (yearsArr.length > 0 && (chosenYear!==null && chosenYear!=="" && chosenYear!==undefined)){
                        for (const arr of yearsArr){
                            if (arr.year === chosenYear && arr.month!==null){
                                if (month === 11){
                                    if (arr.month === 11 || arr.month === 12){
                                        yearObjArr.push(arr);
                                    }
                                }
                                if (arr.month === month){
                                    yearObjArr.push(arr);
                                }
                            }
                        }
                    }
                    return yearObjArr;
                }

                function listOfYearsAndMonths(yearsArr, chosenYear, month){
                    if (yearsArr.length > 0 && (chosenYear!==null && chosenYear!=="" && chosenYear!==undefined)){
                        for (const arr of yearsArr){
                            if (arr.year === chosenYear && arr.month!==null){
                                if (month === 11){
                                    if (arr.month === 12 && (arr.factOld !== null && arr.factOld !== '' && arr.factOld !== 0) && arr.frequency==="001"){
                                        return arr;
                                    } else if (arr.month === month && arr.frequency==="004.007"){
                                        return arr;
                                    }
                                } else if (arr.month === month && arr.frequency==="004.007"){
                                    return arr;
                                }
                            }
                        }
                    }
                    return {};
                }
            },

            //----Заменяет в грид таблице фактические показатели в зависимости от выбор месяца
            setFactValuesByMonths(mainArr, month){
                if (this.forecastArr.length > 0) {
                    // console.log('month: ' + month);
                    // console.log('mainArr: ' + JSON.stringify(mainArr));
                    // console.log('this.forecastArr: ' + JSON.stringify(this.forecastArr));
                    if (mainArr.length > 0) {
                        for (const el of this.forecastArr) {
                            //-------------------------------------------
                            let achievementPercentage = '';
                            let riskOfNotAchieving = false;
                            const riskFormula = 100/12*(this.month+1);
                            const planInit = getPlanYear(this.yearFact, el);
                            //-------------------------------------------
                            // console.log('el: ' + JSON.stringify(el) + ' indicator_ru: ' + el.indicator_ru);
                            if (el.fact.length===0 && (planInit!==null && planInit!=='' && planInit!==0)){
                                achievementPercentage = 0;
                                riskOfNotAchieving = true;
                                this.$set(el, 'achievementPercentage', achievementPercentage);
                                this.$set(el, 'riskOfNotAchieving', riskOfNotAchieving);
                            } else if (el.fact.length>0 && (planInit===null || planInit==='' || planInit===0)){
                                achievementPercentage = '';
                                riskOfNotAchieving = '';
                                this.$set(el, 'achievementPercentage', achievementPercentage);
                                this.$set(el, 'riskOfNotAchieving', riskOfNotAchieving);
                            }
                            else {
                                achievementPercentage = '';
                                riskOfNotAchieving = '';
                                this.$set(el, 'fact', '');
                                this.$set(el, 'achievementPercentage', achievementPercentage);
                                this.$set(el, 'riskOfNotAchieving', riskOfNotAchieving);
                            }
                            for (const item of mainArr) {
                                if (el.forecast_id === item.forecast_id) {
                                    // console.log('elIndicatorName: ' + JSON.stringify(el.indicator_ru) + ' item: ' + JSON.stringify(item));
                                    const fact = item.factOld;
                                    const plan = getPlanYear(this.yearFact, el);
                                    achievementPercentage = (fact/plan)*100;
                                    if (achievementPercentage<riskFormula){
                                        riskOfNotAchieving = true;
                                    } else if (achievementPercentage>=riskFormula){
                                        riskOfNotAchieving =false;
                                    }
                                    if (fact!==null && fact!=="" && plan!==null && plan!=="") {
                                        this.$set(el, 'achievementPercentage', achievementPercentage!==Infinity ? parseFloat(achievementPercentage).toFixed(2) : '');
                                        if (achievementPercentage!==Infinity){
                                            this.$set(el, 'riskOfNotAchieving', false);
                                        }
                                    }
                                    this.$set(el, 'fact', item.factOld);
                                    break;
                                }
                            }
                        }
                    } else {
                        for (const el of this.forecastArr) {
                            this.$set(el, 'fact', '');
                            this.$set(el, 'achievementPercentage', '');
                            this.$set(el, 'riskOfNotAchieving', '');
                        }
                    }
                }
                function getPlanYear(year, el){
                    switch (year) {
                        case el.planOne.year:
                            return el.planOne.value;
                        case el.planTwo.year:
                            return el.planTwo.value;
                        case el.planThree.year:
                            return el.planThree.value;
                        case el.planFour.year:
                            return el.planFour.value;
                        case el.planFive.year:
                            return el.planFive.value;
                        default:
                            return 0;
                    }
                }
            },

            setNameLangs(obj, codeName) {
                let txt = '';
                if (obj!==undefined) {
                    if (codeName === 'npa') {
                        txt = obj.nameRu;
                    } else {
                        txt = obj.name_ru;
                    }
                    if (txt !== undefined) {
                        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                            txt = obj[codeName] + ' - ' + txt;
                        }
                    }
                }
                const el = Object.assign({}, obj);
                el.name = txt;
                return el;
            },
            // ------Метод для отображения сообщения с ошибками----//
            makeToast(variant, title, tostbody) {
                this.$bvToast.toast(tostbody, {
                    title: title,
                    autoHideDelay: 5000,
                    variant: variant,
                    appendToast: true
                });
            } // сообщение с ошибкой
        }
    }
</script>

<style>
</style>