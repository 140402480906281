











































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

interface IAbpItem {
    curAbp: any | null;
    bpList: any[]; // список отфильтрованный без повторов
    bpAllList: any[]; // список всех программ для данной АБП
    curBp: any | null;
    curAbpId: null | number; // временно вместо мультиселект
    curBpId: null | number; // временно вместо мультиселект
}

interface IYears {
    indx: number | 0;
    year: number | 0;
    month: any | null;
    plan: any | null;
    fact: any | null;
    yearsArr: any[];
}

interface IMonth {
    year: any | null;
    month: any | null;
    fact: any | null;
    monthsArr: any[];
}

@Component({
    name: 'indicators-add'
})

export default class CIndicatorsAdd extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private currentForecast!: any;

    @Prop({
        required: true,
        default: null
    })
    private currentProgramPassport!: any;

    @Prop({
        required: true,
        default: null
    })
    private btnParams!: any;

    private inidicatorBtn = true;
    private monthInidicatorBtn = true;
    private curId: null | number = null;
    private years: IYears[] = [];
    private yearsCopy: IYears[] = [];
    private monthFormsArr: IMonth[] = [];
    private monthFormsArrCopy: IMonth[] = [];
    private firstYear: number | null | undefined;
    private lastYear: number | null | undefined;
    private sizeOfYears: number | null | undefined;
    private sizeOfMonth = 12;
    private excludeYears: number | undefined;
    private excludeMonths: number | undefined;
    private chosenYear: any = null;
    private yearsArr: any = [];
    private chosenYearInFilter:any = null;
    private optionsMonths: any = [
        {'name': 'Январь', 'month': 0},
        {'name': 'Январь-Февраль', 'month': 1},
        {'name': 'Январь-Март', 'month': 2},
        {'name': 'Январь-Апрель', 'month': 3},
        {'name': 'Январь-Май', 'month': 4},
        {'name': 'Январь-Июнь', 'month': 5},
        {'name': 'Январь-Июль', 'month': 6},
        {'name': 'Январь-Август', 'month': 7},
        {'name': 'Январь-Сентябрь', 'month': 8},
        {'name': 'Январь-Октябрь', 'month': 9},
        {'name': 'Январь-Ноябрь', 'month': 10},
        {'name': 'Январь-Декабрь', 'month': 11}
    ];

    private dicts: any = {
        indicator: { name: 'Показатель', url: '/api/dict/indicator', items: [] },
        program: { name: 'Документ СГП', url: '/api/dict/program', items: [] },
        direction: { name: 'Направление', url: '/api/dict/direction', items: [] },
        sphere: { name: 'Сфера', url: '/api/dict/sphere', items: [] },
        goals: { name: 'Цели', url: '/api/dict/program_goals', items: [] },
        statKato: { name: 'Като', url: '/api/stat-dict/kato', items: [] },
        statUnit: { name: 'Ед. изм. из стат', url: '/api/stat-dict/unit', items: [] },
        abpList: { name: 'АБП', url: '/api/forecast/dict-ebk-func-abp', items: [] },
        ebkFunc: { name: 'БП', url: '/api/forecast/dict-ebk-func-prg', items: [] },
        executorsList: { name: 'Ответственный исполнитель', url: '/api/forecast-prg-dev/abps', items: [] }
    }

    private abpItem: IAbpItem[] = [];

    private smData = { name: 'Показатель из СЭМ', url: '/api/sm/data?source=СЭМ', items: [] };
    // -- макроиндикатор
    private macroindicator = false;
    private curIndic: any = null;   // -- показатель,
    // ---- наименование целевого индикатора по документы СГП
    private indicatorCustomName = ''; // на русском
    private indicatorCustomNameKz = '';  // на казахском
    private executors: any = [];  // --- ответственный исполнитель

    private get indicatorDict() {
        const result = [];
        for (const el of this.dicts.indicator.items) {
            result.push(this.setNameLang(el));
        }
        return result;
    }
    // --- документ СГП
    private curProgram: any = null;
    private curProgramName: String = "";
    // -- вышестоящий док. СГП
    private curSgpParentDoc: any = null;

    private get parentProgramDict() {
        const result = [];
        for (const el of this.dicts.program.items) {
            if (!this.curProgram || this.curProgram.code !== el.code) {
                result.push(this.setNameLang(el));
            }
        }
        return result;
    }

    // ---ед измерения
    private curUnit: any = null;

    private get unitDict() {
        const result = [];
        for (const el of this.dicts.statUnit.items) { result.push(this.setNameLang(el)); }
        return result;
    }
    // -- направление
    private curDirect: any = null;

    private get directDict() {
        const result = [];
        for (const el of this.dicts.direction.items) { result.push(this.setNameLang(el)); }
        return result;
    }
    // ----- сфера
    private curSphere: any = null;
    // --- цель
    private curGoal: any = null;
    //----Ответственный исполнитель
    private get respExecutor() {
        const result = [];
        for (const el of this.dicts.executorsList.items) { result.push(this.setNameLang(el)); }
        // console.log('result: ' + JSON.stringify(result));
        return result;
    }
    private get goalDict() {
        const result = [];
        for (const el of this.dicts.goals.items) { result.push(this.setNameLang(el)); }
        return result;
    }
    // --абп
    private get abpDict() {
        const result = [];
        for (const el of this.dicts.abpList.items) {
            result.push(this.setNameLang(el, 'abp'));
        }
        return result;
    }
    // --бп
    private get bpDict() {
        const result = [];
        for (const el of this.dicts.ebkFunc.items) {
            result.push(this.setNameLang(el, 'prg'));
        }
        return result;
    }
    // --като
    private curKato: any = null;
    private curKatoName: String = "";
    //---Целевой индикатор на русс.яз.
    private targetIndicatorRu: any = null;
    //---Целевой индикатор на каз.яз.
    private targetIndicatorKz: any = null;
    // --показатель из СЭМ
    private curSmIndic: any = null;

    private get smIndicDict() {
        const result = [];
        for (const el of this.smData.items) { result.push(this.setNameLang(el, 'code')); }
        return result;
    }

    private async created() {
        const that = this;
        await this.loadDict(this.dicts.indicator);
        await this.loadDict(this.dicts.program);
        await this.loadDict(this.dicts.direction);
        await this.loadDict(this.dicts.sphere);
        await this.loadDict(this.dicts.goals);
        await this.loadDict(this.dicts.statKato);
        await this.loadDict(this.dicts.statUnit);
        await this.loadDict(this.dicts.abpList);
        await this.loadDict(this.dicts.ebkFunc);
        await this.loadDict(this.dicts.executorsList);
        await this.loadData(this.smData);

        // ------
        this.abpItem = [];
        this.years = [];
        this.monthFormsArr = [];
        this.$watch('currentProgramPassport.programPassportId', this.setParams);
        this.$watch('currentForecast', that.setFilter);
        this.$watch('btnParams', that.setBtnParams);
    }

    private setBtnParams(){
        // console.log('btnParams: ' + this.btnParams);
    }

    // ---- загрузка
    private async loadDict(dict: any) {
        await fetch(dict.url).then(response => response.json()).then(json => {
            dict.items = json;
        });
    }

    private loadData(d: any) {
        fetch(d.url).then(response => response.json())
            .then(json => {
                json.forEach((item: any) => {
                    if (item.indicatorName) { item.name_ru = item.indicatorName; }
                    if (item.indicator) {
                        if (item.indicator.name_ru) {
                            item.name_ru = item.indicator.name_ru;
                            item.indicatorName = item.indicator.name_ru;
                        }
                        if (item.indicator.name_kk) { item.name_kk = item.indicator.name_kk; }
                    }
                });
                d.items = json;
            });
    }
    // -------------
    private getProgramName(prgId: any) {
        for (const el of this.dicts.program.items) {
            if ((!this.curSgpParentDoc || this.curSgpParentDoc.code !== el.code) && el.id === prgId) {
                return el;
            }
        }
        return null;
    }

    private selectAbp(abpItem: IAbpItem, indx?: number) {
        abpItem.bpList = [];
        if (abpItem.curAbp === null || this.bpDict === null) {
            abpItem.curAbpId = null;
            return;
        }
        abpItem.curAbpId = abpItem.curAbp.id; // временно мультиселект
        let fl = false;
        if (abpItem.curBp === null) { fl = true; }
        abpItem.bpAllList = this.bpDict.filter(item => {
            if (abpItem.curAbp.abp === item.abp && abpItem.curBp !== null && item.id === abpItem.curBp.id) { fl = true; }
            return abpItem.curAbp.abp === item.abp;
        });
        if (indx) {
            this.getBpList(indx);
        } else {
            abpItem.bpList = abpItem.bpAllList;
        }
        if (!fl) {
            abpItem.curBp = null;
            abpItem.curBpId = null; // временно мультиселект
        }
    }

    private getBpList(indx: number) { // фильтр чтобы не было дублей по программам
        this.abpItem[indx].bpList = this.abpItem[indx].bpAllList.filter(item => {
            let doubleEl = false;
            for (let i = 0; i < this.abpItem.length; i++) {
                const el = this.abpItem[i];
                if (i !== indx && el.curAbp.abp === item.abp && el.curBp && el.curBp.prg === item.prg) {
                    doubleEl = true;
                    break;
                }
            }
            return !doubleEl;
        });
    }

    private addAbpBp() {
        this.abpItem.push({ bpList: [], bpAllList: [], curBp: null, curAbp: null, curAbpId: null, curBpId: null });
    }
//------------------------------------------------------------------------------------------------------
    private getYearFromList(chosenYear: any, years: any, indx: any) {
        const constantInitArr = Object.assign([], this.yearsCopy);
        const isYearInArr = checkChosenYearInArr(chosenYear.year, years);
        if (!isYearInArr) {
            this.excludeYears = chosenYear.year;
        } else {
            this.makeToast('danger', 'Ошибка', 'Выбранный '+chosenYear.year+' год уже используется');
            if (indx <= constantInitArr.length-1) {
                if (constantInitArr.length > 0 && years.length > 0){
                    years[indx] = Object.assign({}, constantInitArr[indx]);
                    this.$set(years, indx, constantInitArr[indx]);
                }
            } else {
                this.years.splice(indx, 1);
                this.inidicatorBtn = true;
            }
        }
        function checkChosenYearInArr(chosenYear: any, years: any[]) {
            const arrForCountYears = [];
            if (chosenYear > 0 && years.length > 0){
                for (const item of years){
                    if (item.year === chosenYear){
                        arrForCountYears.push(item.year);
                    }
                }
            }
            if (arrForCountYears.length > 1){
                return  true;
            }
            return false;
        }
    }

    private get yearsList(): IYears[]{
        return this.years;
    }

    private addIndicators() {
        if (this.years.length<this.yearsArr.length-1) {
            this.years.push({indx: 0, year: 0, month: 0, plan: 0, fact: 0, yearsArr: this.yearsArr});
        } else {
            this.years.push({indx: 0, year: 0, month: 0, plan: 0, fact: 0, yearsArr: this.yearsArr});
            this.inidicatorBtn = false;
        }
    }

    private getMonthFromList(chosenMonth: any, indx: any) {
        const constantInitMonths = Object.assign([], this.monthFormsArrCopy);
        const isMonthInArr = this.checkChosenMonthInArr(chosenMonth.month, this.monthFormsArr);
        // console.log('this.chosenMonth: ' + JSON.stringify(chosenMonth) + ' isMonthInArr: ' + isMonthInArr);
        if (!isMonthInArr) {
            this.excludeMonths = chosenMonth.value;
        } else {
            this.makeToast('danger', 'Ошибка', 'Выбранный ' + chosenMonth.monthsArr[chosenMonth.month].name + ' месяц уже используется');
            if (indx <= constantInitMonths.length-1) {
                if (constantInitMonths.length > 0 && this.monthFormsArr.length > 0){
                    this.monthFormsArr[indx] = Object.assign({}, constantInitMonths[indx]);
                    this.$set(this.monthFormsArr, indx, constantInitMonths[indx]);
                }
            } else {
                this.monthFormsArr.splice(indx, 1);
                this.monthInidicatorBtn = true;
            }
        }
    }

    private addMonthIndicators() {
        const isDuplicateInMonth = this.checkYearsForDuplicates([], this.monthFormsArr, "months"); //--Проверка на дубликаты в месячных показателях
        // console.log('isDuplicateInMonth: ' + isDuplicateInMonth);
        if (!isDuplicateInMonth) {
            if (this.monthFormsArr.length < this.optionsMonths.length - 1) {
                this.monthFormsArr.push({year: 0, month: 0, fact: 0, monthsArr: this.optionsMonths});
            } else {
                this.monthFormsArr.push({year: 0, month: 0, fact: 0, monthsArr: this.optionsMonths});
                this.monthInidicatorBtn = false;
            }
        }
    }

    private checkChosenMonthInArr(chosenMonth: any, monthFormsArr: any[]) {
        const arrForCountMonths = [];
        if (chosenMonth >= 0 && monthFormsArr.length > 0){
            for (const item of monthFormsArr){
                if (item.month === chosenMonth){
                    arrForCountMonths.push(item.month);
                }
            }
        }
        if (arrForCountMonths.length > 1){
            return  true;
        }
        return false;
    }

    private setParams(){
        if (this.btnParams === 'add' || this.btnParams === 'edit') {
            // console.log("setParams: " + JSON.stringify(this.currentProgramPassport));
            const program = this.getProgramName(this.currentProgramPassport.programId);
            // console.log('program: ' + JSON.stringify(program));
            if (program!==null) {
                this.curProgramName = program.name_ru;
                this.curProgram = program;
                this.curKatoName = program.statKato.name_ru;
                this.curKato = program.statKato;
            }
            this.chosenYearInFilter = this.currentProgramPassport.chosenYear;
            const currentYear = new Date().getFullYear();
            const startDate = new Date(this.currentProgramPassport.startDate).getFullYear();
            const endDate = new Date(this.currentProgramPassport.endDate).getFullYear();
            this.sizeOfYears = (endDate - startDate) + 1;
            this.firstYear = startDate;
            this.lastYear = endDate;
            this.listOfYears(currentYear, startDate, endDate);
        }
    }

    private setFilter() {
        this.setParams();
        const currentForecast = this.currentForecast;
        // console.log('currentForecast: ' + JSON.stringify(currentForecast));
        this.curSmIndic = null;
        if (currentForecast === null || JSON.stringify(currentForecast) === '{}') {
            this.curId = null;
            this.curIndic = null;
            this.curUnit = null;
            this.curDirect = null;
            this.curGoal = null;
            this.indicatorCustomName = '';
            this.indicatorCustomNameKz = '';
            this.executors = [];
            this.abpItem = [];
            this.years = [];
            this.curSgpParentDoc = null;
            this.macroindicator = false;
        } else if (currentForecast.forecast !== null) {
            // console.log('currentForecast: ' + JSON.stringify(currentForecast.forecast));
            this.curId = currentForecast.forecast.id;
            this.macroindicator = currentForecast.forecast.macroindicator;
            this.curIndic = this.setNameLang(currentForecast.forecast.indicator);
            this.curUnit = this.setNameLang(currentForecast.forecast.statUnit);
            this.curProgram = this.setNameLang(currentForecast.forecast.program);
            this.curSgpParentDoc = this.setNameLang(currentForecast.forecast.sgpParentDoc);
            this.curDirect = currentForecast.forecast.direction!==null ? this.setNameLang(currentForecast.forecast.direction) : '';
            this.curGoal = currentForecast.forecast.goals!==null ? this.setNameLang(currentForecast.forecast.goals) : '';
            if (currentForecast.forecast.indicatorCustomName !== null) { this.indicatorCustomName = currentForecast.forecast.indicatorCustomName; } else { this.indicatorCustomName = ''; }
            if (currentForecast.forecast.indicatorCustomNameKz !== null) { this.indicatorCustomNameKz = currentForecast.forecast.indicatorCustomNameKz; } else { this.indicatorCustomNameKz = ''; }
            this.curKato = this.setNameLang(currentForecast.forecast.statKato);
            if (currentForecast.forecast.smData) { this.curSmIndic = Object.assign({ name: currentForecast.forecast.smData.indicatorName }, currentForecast.forecast.smData); }
            // -------------- abpItem
            this.abpItem = [];
            if (currentForecast.forecast.abps && currentForecast.forecast.abps.length > 0) {
                this.abpItem = currentForecast.forecast.abps.map((item: any) => {
                    const abpSelect: IAbpItem = { curAbp: null, curBp: null, bpList: [], bpAllList: [], curAbpId: null, curBpId: null };
                    for (let i = 0; i < this.abpDict.length; i++) {
                        if (this.abpDict[i].abp === item.abp) {
                            abpSelect.curAbp = this.abpDict[i];
                            break;
                        }
                    }
                    this.selectAbp(abpSelect);
                    for (let i = 0; i < abpSelect.bpList.length; i++) {
                        const itemEbk = abpSelect.bpList[i];
                        if (itemEbk.abp === item.abp && itemEbk.gr === item.gr && itemEbk.pgr === item.pgr && itemEbk.ppr === item.ppr && itemEbk.prg === item.prg) {
                            abpSelect.curBp = itemEbk;
                            if (itemEbk !== null) { abpSelect.curBpId = itemEbk.id; }
                            break;
                        }
                    }

                    return abpSelect;
                });
                // console.log('this.abpItem: ' + JSON.stringify(this.abpItem));
                for (let i = 0; i < this.abpItem.length; i++) { this.getBpList(i); }
            }
            this.executors = getExecutors(currentForecast.forecast.executors, this.dicts.executorsList.items);
            // -----------
            this.years = [];
            const yearsArrItems = [];
            const arrForYears = Object.assign([], this.yearsArr);
            this.monthFormsArr = [];
            const arrForMonth = Object.assign([], this.optionsMonths);

            if (currentForecast.forecast.years && currentForecast.forecast.years.length) {
                for (const forecastYear of currentForecast.forecast.years){
                    if (forecastYear.frequency === "001"){
                        const yearObj = {
                            'indx': 0,
                            'year': forecastYear.year,
                            'plan': forecastYear.plan ? forecastYear.plan : 0,
                            'month': parseInt('12'),
                            'fact': forecastYear.factOld ? forecastYear.factOld : 0,
                            'yearsArr': arrForYears
                        };
                        yearsArrItems.push(yearObj);
                        this.yearsCopy.push(Object.assign({}, yearObj));
                    } else {
                        const monthObj = {
                            'year': forecastYear.year,
                            'month': forecastYear.month,
                            'fact': forecastYear.factOld ? forecastYear.factOld : 0,
                            'monthsArr': arrForMonth
                        }
                        this.monthFormsArr.push(monthObj);
                        this.monthFormsArrCopy.push(Object.assign({}, monthObj));
                    }
                }
            }
            this.years = yearsArrItems;
        }

        function getExecutors(executors: any[], executorsList: any[]){
            const executorArr = [];
            if (executors.length > 0) {
                for (const el of executorsList) {
                    for (const executor of executors){
                        if (el.abp === executor.dict_ebk_func_abp){
                            const abpObj = {
                                "abp": el.abp,
                                "name_ru": el.name_ru,
                                "name_kk": el.name_kk,
                            }
                            executorArr.push(abpObj);
                            break;
                        }
                    }
                }
                // console.log('tempArr: ' + JSON.stringify(tempArr));
            }
            return executorArr;
        }
    }

    private async prepareToSave() {
        const monthFrequency = '004.007';
        for (let i = 0; i < this.years.length; i++) {
            this.years[i].indx = i;
        }
        const isDuplicateInYears = this.checkYearsForDuplicates(this.years, [], "years"); //--Проверка на дубликаты в годовых показателях
        const isDuplicateInMonths = this.checkYearsForDuplicates([], this.monthFormsArr, "months");  //--Проверка на дубликаты в месячных показателях
        // console.log('isDuplicateInYears: ' + isDuplicateInYears + ' isDuplicateInMonths: ' + isDuplicateInMonths);
        if (!isDuplicateInYears && !isDuplicateInMonths) {
            let obj: any;
            // console.log('this.currentForecast: ' + JSON.stringify(this.currentForecast));
            if (this.btnParams === 'add') {
                obj = Object.assign({}, this.currentForecast);
            } else if (this.btnParams === 'edit') {
                obj = {
                    id: this.curId,
                    indicator: null,
                    program: this.curProgram,
                    direction: null,
                    sphere: null,
                    goals: null,
                    unit: null,
                    statKato: null,
                    statUnit: null,
                    executors: [],
                    years: [],
                    abpsSelect: [],
                    abps: [],
                    statSetting: null,
                    coefficient: null,
                    arithmetic_operation: null,
                    sgpParentDoc: null
                };
            }
            // ----
            obj.indicator = this.curIndic !== null && this.curIndic !== undefined ? this.curIndic : null;
            obj.statUnit = this.curUnit;
            obj.program = this.curProgram;
            obj.direction = this.curDirect;
            obj.goals = this.curGoal;
            obj.indicatorCustomName = this.indicatorCustomName.trim().length > 0 ? this.indicatorCustomName.trim() : null;
            obj.indicatorCustomNameKz = this.indicatorCustomNameKz.trim().length > 0 ? this.indicatorCustomNameKz.trim() : null;
            obj.statKato = this.curKato;
            obj.executors = [];
            obj.smData = this.curSmIndic;
            obj.years = [];
            obj.macroindicator = this.macroindicator;
            obj.sgpParentDoc = this.curSgpParentDoc;

            obj.executors = getListOfExecutors(this.executors);
            obj.years = getListOfYearsWithMonthsForSaving(this.years, this.monthFormsArr, this.chosenYearInFilter);
            // ----
            if (!obj.indicator || !obj.program || !obj.statKato || !obj.statUnit || !obj.years.length || !obj.executors.length) {
                this.makeToast('danger', 'Сохранение', 'Не заполнены обязательные поля!');
                return;
            }

            obj.abps = this.abpItem
                .filter(item => item.curBp && item.curBp.id)
                .map(item => {
                    return {
                        gr: item.curBp.gr,
                        pgr: item.curBp.pgr,
                        abp: item.curBp.abp,
                        prg: item.curBp.prg,
                        ppr: item.curBp.ppr
                    };
                });

            // console.log('obj: ' + JSON.stringify(obj));

            await this.saveToDb(obj);
        } else {
            this.makeToast('danger', 'Сохранение', 'В значениях (годовых/месячных) показателях имеются дубликаты');
        }

        function getListOfExecutors(executors: any) {
            const execArr = [];
            for (const el of executors) {
                const res = {dict_ebk_func_abp: parseInt(el.abp)}
                execArr.push(res);
            }
            return execArr;
        }

        function getListOfYearsWithMonthsForSaving(years: IYears[], monthFormsArr: IMonth[], chosenYearInFilter: any) {
            const yearFrequency = '001';
            const yearsArr = [];

            //---years
            for (const el of years) {
                let res = {};
                if (el.month === 0) {
                    res = {
                        year: parseInt(el.year.toString()),
                        month: parseInt("12"),
                        plan: parseFloat(el.plan.toString()),
                        factOld: parseFloat(el.fact.toString()),
                        frequency: yearFrequency.toString()
                    };
                } else {
                    res = {
                        year: parseInt(el.year.toString()),
                        month: parseInt(el.month),
                        plan: parseFloat(el.plan.toString()),
                        factOld: parseFloat(el.fact.toString()),
                        frequency: yearFrequency.toString()
                    };
                }
                yearsArr.push(res);
            }

            //---months
            for (const el of monthFormsArr) {
                yearsArr.push({
                    year: parseInt(chosenYearInFilter),
                    month: parseInt(el.month),
                    factOld: parseFloat(el.fact.toString()),
                    frequency: monthFrequency.toString()
                });
            }

            return yearsArr;
        }
    }

    //----Поиск дубликатов по годовым и месячным значениям
    private checkYearsForDuplicates(yearsArr: IYears[], monthsArr: IMonth[], typeOfArr: string) {
        const arr: any[] = (typeOfArr=="years" && yearsArr.length > 0) ? yearsArr : (typeOfArr=="months" && monthsArr.length>0) ? monthsArr : [];
        if (arr.length > 0) {
            if (typeOfArr=="years") {
                const counter = arr.reduce(function (item: any, el: any) {
                    if (!item.hasOwnProperty(el.year)) {
                        item[el.year] = 0;
                    }
                    item[el.year]++;
                    return item;
                }, {});
                const result: any[] = Object.keys(counter).map(function (year) {
                    return {year: year, sum: counter[year]};
                });
                for (const res of result) {
                    if (res.sum > 1) {
                        return true;
                    }
                }
            }
            else if (typeOfArr=="months"){
                const counter = arr.reduce(function (item: any, el: any) {
                    if (!item.hasOwnProperty(el.month)) {
                        item[el.month] = 0;
                    }
                    item[el.month]++;
                    return item;
                }, {});
                const result: any[] = Object.keys(counter).map(function (month: any) {
                    return {month: month, sum: counter[month]};
                });
                for (const res of result) {
                    if (Object.is(res.month, '0') && res.sum > 1) {
                        return true;
                    }
                    if (!Object.is(res.month, '0') && res.sum > 1) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private async saveToDb(obj: any) {
        const method = obj.id ? 'PUT' : 'POST';
        const url = obj.id ? '/api/forecast-prg-dev/edit' : '/api/forecast-prg-dev/add';

        let result: any = await fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: method,
                body: JSON.stringify(obj)
            });
        if (result.status === 200) {
            result = await result.json();
            this.makeToast('success', 'Сохранение', 'Данные сохранены!');
            if (result.id) {
                this.curId = result.id;
            }
            this.inidicatorBtn = true;
            this.setParams();
            this.$emit('saved', result);
            this.closeEdit();
        } else {
            this.makeToast('danger', 'Сохранение', `Ошибка ${result.status} ${result.statusText}`);
        }
    }

//------------------------------------------------------------------------------------------------------
    private closeEdit() {
        this.curId = null;
        this.curIndic = null;
        this.curUnit = null;
        this.curProgram = null;
        this.curDirect = null;
        this.curSphere = null;
        this.curGoal = null;
        this.targetIndicatorRu = '';
        this.targetIndicatorKz = '';
        this.curKato = null;
        this.executors = [];
        this.abpItem = [];
        this.years = [];
        this.yearsCopy = [];
        this.monthFormsArr = [];
        this.monthFormsArrCopy = [];
        this.curSgpParentDoc = null;
        this.curSmIndic = null;
        this.macroindicator = false;
        this.$emit('closeEdit', true);
        this.inidicatorBtn = true;
    }

    private removeYears(indx: number) {
        if (this.sizeOfYears!==null && this.sizeOfYears!==undefined){
            this.years.splice(indx, 1);
            this.yearsCopy.splice(indx, 1);
            this.sizeOfYears++;
            this.inidicatorBtn = true;
        }
    }


    private removeMonths(indx: number){
        this.monthFormsArr.splice(indx, 1);
        this.monthFormsArrCopy.splice(indx, 1);
        this.sizeOfMonth++;
        this.monthInidicatorBtn = true;
    }

    private removeAbpBp(indx: number) {
        this.abpItem.splice(indx, 1);
        for (let i = 0; i < this.abpItem.length; i++) {
            this.getBpList(i);
        }
    }

    private listOfYears(currentYear: number | null, startDate: number | null, endDate: number | null) {  //-----Список годов в фильтрации по годам----//
        this.yearsArr = [];
        if (currentYear !== null && startDate !== null && endDate !== null) {
            const curYear = currentYear;
            const start_date = startDate;
            const end_date = endDate;
            if (this.yearsArr.length === 0){
                for (let i = end_date; i >= start_date; i--) {
                    if (end_date >= curYear) {
                        if (i === curYear) this.chosenYear = curYear;
                    } else {
                        this.chosenYear = end_date;
                    }
                    this.yearsArr.push(i);
                }
            }
        }
    }

    private chgBpBform(indx: number) {
        const item = this.abpItem[indx];
        if (item.curBpId === null) {
            item.curBp = null;
            for (let i = 0; i < this.abpItem.length; i++) {
                if (i !== indx) { this.getBpList(i); }
            }

            return;
        }
        for (const el of item.bpList) {
            if (el.id === item.curBpId) {
                item.curBp = el;
                for (let i = 0; i < this.abpItem.length; i++) {
                    if (i !== indx) { this.getBpList(i); }
                }
                return;
            }
        }
    }

    // ------------------ временно вместо multiselect
    private chgAbpbForm(indx: number) {
        const item = this.abpItem[indx];
        if (item.curAbpId === null) {
            item.curAbp = null;
            this.selectAbp(item, indx);
            return;
        }
        for (const el of this.abpDict) {
            if (el.id === item.curAbpId) {
                item.curAbp = el;
                this.selectAbp(item, indx);
                return;
            }
        }
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = '';
        if (obj!==undefined && obj!==null) {
            if (codeName === 'npa' || codeName === 'abp' || codeName === 'prg') {
                txt = obj.nameRu;
            } else {
                txt = obj.name_ru;
            }
            if (txt !== undefined) {
                if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                    txt = obj[codeName] + ' - ' + txt;
                }
            }
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
}
