    <template>
        <div>
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <!------------------Фильтрация по годам------------------->
                            <div class="filter-block">
                                <b-form-group :label="yearItem">
                                    <multiselect
                                        v-model="chosenYear"
                                        :options="yearsArr"
                                        placeholder="Год"
                                        @input="chgParams"
                                        :allow-empty="false"
                                        :show-labels="false"
                                    />
                                </b-form-group>
                            </div>
                            <!---------------Направления--------------------->
                            <div class="filter-block">
                                <b-form-group :label="filterNameParams[0].name_ru" class="small">
                                    <multiselect
                                        v-model="dictDirectionVal"
                                        track-by="name"
                                        label="name"
                                        placeholder="Направления"
                                        :options="directionArr"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgSubParams(dictDirectionVal)"
                                    >
                                        <template
                                            slot="singleLabel"
                                            slot-scope="{ option }"
                                        >
                                            <template v-if="option.name!==undefined">
                                                <span v-if="option.name.length<16">{{ option.name }}</span>
                                                <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                            </template>
                                        </template>
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------Цели------------------------------------->
                            <div class="filter-block">
                                <b-form-group :label="filterNameParams[1].name_ru">
                                    <multiselect
                                        v-model="dictProgramGoalVal"
                                        track-by="name"
                                        label="name"
                                        placeholder="Цели"
                                        :options="programGoalArr"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgSubParams(dictProgramGoalVal)"
                                    >
                                        <template
                                            slot="singleLabel"
                                            slot-scope="{ option }"
                                        >
                                            <template v-if="option.name!==undefined">
                                                <span v-if="option.name.length<16">{{ option.name }}</span>
                                                <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                            </template>
                                        </template>
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------Целевые индикаторы----------------------->
                            <div class="filter-block">
                                <b-form-group :label="filterNameParams[2].name_ru" class="small">
                                    <multiselect
                                        v-model="dictGoalIndicatorVal"
                                        track-by="name"
                                        label="name"
                                        placeholder="Целевые индикаторы"
                                        :options="goalIndicatorArr"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgSubParams(dictGoalIndicatorVal)"
                                    >
                                        <template
                                            slot="singleLabel"
                                            slot-scope="{ option }"
                                        >
                                            <template v-if="option.name!==undefined">
                                                <span v-if="option.name.length<16">{{ option.name }}</span>
                                                <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                            </template>
                                        </template>
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------Вышестоящий документ--------------------->
                            <div class="filter-block">
                                <b-form-group :label="filterNameParams[3].name_ru">
                                    <multiselect
                                        v-model="dictProgramVal"
                                        track-by="name"
                                        label="name"
                                        placeholder="Вышестоящий документ"
                                        :options="programArr"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgSubParams(dictProgramVal)"
                                    >
                                        <template
                                            slot="singleLabel"
                                            slot-scope="{ option }"
                                        >
                                            <template v-if="option.name!==undefined">
                                                <span v-if="option.name.length<16">{{ option.name }}</span>
                                                <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                            </template>
                                        </template>
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------Регион--------------------->
                            <div class="filter-block">
                                <b-form-group :label="filterNameParams[4].name_ru">
                                    <multiselect
                                        v-model="dictOblRegVal"
                                        track-by="name"
                                        label="name"
                                        placeholder="Регион"
                                        :options="oblRegArr"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgSubParams(dictOblRegVal)"
                                    >
                                        <template
                                            slot="singleLabel"
                                            slot-scope="{ option }"
                                        >
                                            <template v-if="option.name!==undefined">
                                                <span v-if="option.name.length<16">{{ option.name }}</span>
                                                <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                            </template>
                                        </template>
                                    </multiselect>
                                </b-form-group>
                            </div>
                        </div>
                    </b-dropdown>

                    <b-form-radio-group
                        id="radio-indicators"
                        name="radio-indicators"
                        class="ml-3 text-uppercase"
                        v-model="selected"
                        :options="optionsFilter"
                    >
                    </b-form-radio-group>
                </div>
            </div>

            <template v-if="selected === 1">
                <ListCI @listOfForecast = "listOfForecast" :headParamsQuery="headParamsQuery" :headParams="headParams" :currentProgramPassport="currentProgramPassport"/>
            </template>
        </div>
    </template>


    <script>
        import ListCI from "@/modules/development-program/tabs/indicators/ListCI";

        export default {
            name: 'indicators',
            components: {
                ListCI
            },
            props: {
                currentProgramPassport: {
                    required: true,
                    default: null
                }
            },
            created() {
                this.currentYear = new Date().getFullYear();
                this.$watch('currentProgramPassport.programPassportId', this.setPasspParams);
            },
            data() {
                return {
                    edit: false,
                    selected: 1,
                    optionsFilter: [
                        { text: 'Список ЦИ', value: 1 },
                        { text: 'Статус', value: 2 },
                    ],
                    yearItem: 'Год',
                    currentYear: null,
                    chosenYear: null,
                    yearsArr: [],
                    currentForecast: null,
                    prgId: null,
                    prgPasspId: null,
                    filterNameParams: [
                    {
                        name_ru: 'Направление ',
                        name_kk: 'Направление ',
                        name_en: 'Direction'
                    },
                    {
                        name_ru: 'Цель',
                        name_kk: 'Цель',
                        name_en: 'Target'
                    },
                    {
                        name_ru: 'Целевой индикатор',
                        name_kk: 'Целевой индикатор',
                        name_en: 'Target indicator'
                    },
                    {
                        name_ru: 'Вышестоящий документ',
                        name_kk: 'Вышестоящий документ',
                        name_en: 'Parent Document'
                    },
                    {
                        name_ru: 'Регион',
                        name_kk: 'Регион',
                        name_en: 'Region'
                    },
                    {
                        name_ru: 'Версия',
                        name_kk: 'Версия',
                        name_en: 'Version'
                    }
                ],
                    all: {
                        name_ru: 'Все',
                        name_en: 'All',
                        name_kk: 'Барлық',
                        id: ''
                    },
                    dictDirectionVal: null,
                    directionArr: [],
                    directionArrInit: [],
                    dictProgramGoalVal: null,
                    programGoalArr: [],
                    programGoalArrInit: [],
                    dictGoalIndicatorVal: null,
                    goalIndicatorArr: [],
                    goalIndicatorArrInit: [],
                    dictProgramVal: null,
                    programArr: [],
                    programArrInit: [],
                    dictOblRegVal: null,
                    oblRegArr: [],
                    oblRegArrInit: [],
                    oblast: null,
                    filterParams: null,
                    headParams: {},
                    headParamsQuery: {},
                    mainArrForFilter: [],
                    filterParamsArr: [],
                    firstFilterParams: {
                        directionArr: [],
                        programGoalArr: [],
                        goalIndicatorArr: [],
                        programArr: [],
                        oblRegArr: []
                    },
                    secondFilterParams: {
                        directionArr: [],
                        programGoalArr: [],
                        goalIndicatorArr: [],
                        programArr: [],
                        oblRegArr: []
                    }
                }
            },
            methods: {
                //-----Список годов в фильтрации по годам----//
                listOfYears(currentYear, startDate, endDate) {
                    this.yearsArr = [];
                    if (currentYear !== null && startDate !== null && endDate !== null) {
                        const curYear = parseInt(currentYear);
                        const start_date = parseInt(startDate);
                        const end_date = parseInt(endDate);
                        for (let i = end_date; i >= start_date; i--) {
                            if (end_date >= curYear) {
                                if (i === curYear) this.chosenYear = curYear;
                            } else {
                                this.chosenYear = end_date;
                            }
                            this.yearsArr.push(i);
                        }
                    }
                },

                // ----Передача параметров из шапки с фильтрами----//
                chgParams() {   // передаются параметры фильтра на гриду
                    this.headParamsQuery = {
                        year: this.chosenYear,
                        oblast: this.oblast,
                        prgId: this.prgId
                    }
                    this.currentProgramPassport.chosenYear = this.chosenYear;
                },

                chgSubParams(value){
                    const directionArr = this.directionArrInit;     //---Направление
                    const programGoalArr = this.programGoalArrInit;   //---массив для справочника Целей
                    const goalIndicatorArr = this.goalIndicatorArrInit; //---Целевой индикатор
                    const programArr = this.programArrInit;       //---Вышестоящий документ
                    const oblRegArr = this.oblRegArrInit;         //---Регион

                    this.headParams = {
                        direction: this.dictDirectionVal,
                        target: this.dictProgramGoalVal,
                        targetIndicator: this.dictGoalIndicatorVal,
                        sgpProgram: this.dictProgramVal,
                        codeKato: this.dictOblRegVal
                    };

                    this.directionArr = this.getFilteredDirection(directionArr, this.dictProgramGoalVal, this.dictGoalIndicatorVal, this.dictProgramVal, this.dictOblRegVal);
                    this.programGoalArr = this.getFilteredGoals(programGoalArr, this.dictDirectionVal, this.dictGoalIndicatorVal, this.dictProgramVal, this.dictOblRegVal);
                    this.goalIndicatorArr = this.getFilteredIndicator(goalIndicatorArr, this.dictDirectionVal, this.dictProgramGoalVal, this.dictProgramVal, this.dictOblRegVal);
                    this.programArr = this.getFilteredSgpParentDoc(programArr, this.dictDirectionVal, this.dictProgramGoalVal, this.dictGoalIndicatorVal, this.dictOblRegVal);
                    this.oblRegArr = this.getFilteredOblRegions(oblRegArr, this.dictDirectionVal, this.dictProgramGoalVal, this.dictGoalIndicatorVal, this.dictProgramVal);
                },

                getFilteredDirection(directionArr, goals_id, indicator_id, sgpParentDoc_id, statKato_id){
                    const result = [];
                    if (directionArr.length > 0){
                        if (goals_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'direction'));
                        } else if (indicator_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'goal_indicator'));
                        } else if (sgpParentDoc_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'sgpProgram'));
                        } else if (statKato_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'regObl'));
                        }

                        for (const direct of directionArr){
                            if (goals_id.id==='' && indicator_id.id==='' && sgpParentDoc_id.id==='' && statKato_id.id===''){
                                result.push(direct);
                            } else {
                                if ((goals_id.id!=='' && direct.goals_id === goals_id.id)
                                    &&
                                    (indicator_id.id!=='' && direct.indicator_id === indicator_id.id)
                                    &&
                                    (sgpParentDoc_id.id!=='' && direct.sgpParentDoc_id===sgpParentDoc_id.id)
                                    &&
                                    (statKato_id.id!=='' && direct.statKato_id===statKato_id.id))
                                {
                                    result.push(direct);
                                } else if ((indicator_id.id==='' && sgpParentDoc_id.id==='' && statKato_id.id==='') && (goals_id.id!=='' && direct.goals_id === goals_id.id)){
                                    result.push(direct);
                                } else if ((goals_id.id==='' && sgpParentDoc_id.id==='' && statKato_id.id==='') && (indicator_id.id!=='' && direct.indicator_id === indicator_id.id)){
                                    result.push(direct);
                                } else if ((goals_id.id==='' && indicator_id.id==='' && statKato_id.id==='') && (sgpParentDoc_id.id!=='' && direct.sgpParentDoc_id === sgpParentDoc_id.id)){
                                    result.push(direct);
                                } else if ((goals_id.id==='' && indicator_id.id==='' && sgpParentDoc_id.id==='') && (statKato_id.id!=='' && direct.statKato_id === statKato_id.id)){
                                    result.push(direct);
                                }
                            }
                        }
                    }
                    return result;
                },

                getFilteredGoals(programGoalArr, direction_id, indicator_id, sgpParentDoc_id, statKato_id){
                    const result = [];
                    if (programGoalArr.length > 0){
                        if (direction_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'goal'));
                        } else if (indicator_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'goal_indicator'));
                        } else if (sgpParentDoc_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'sgpProgram'));
                        } else if (statKato_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'regObl'));
                        }
                        for (const goal of programGoalArr){
                            if (direction_id.id==='' && indicator_id.id==='' && sgpParentDoc_id.id==='' && statKato_id.id===''){
                                result.push(goal);
                            } else {
                                if ((direction_id.id!=='' && goal.direction_id === direction_id.id)
                                    &&
                                    (indicator_id.id!=='' && goal.indicator_id === indicator_id.id)
                                    &&
                                    (sgpParentDoc_id.id!=='' && goal.sgpParentDoc_id===sgpParentDoc_id.id)
                                    &&
                                    (statKato_id.id!=='' && goal.statKato_id===statKato_id.id))
                                {
                                    result.push(goal);
                                } else if ((indicator_id.id==='' && sgpParentDoc_id.id==='' && statKato_id.id==='') && (direction_id.id!=='' && goal.direction_id === direction_id.id)){
                                    result.push(goal);
                                } else if ((direction_id.id==='' && sgpParentDoc_id.id==='' && statKato_id.id==='') && (indicator_id.id!=='' && goal.indicator_id === indicator_id.id)){
                                    result.push(goal);
                                } else if ((direction_id.id==='' && indicator_id.id==='' && statKato_id.id==='') && (sgpParentDoc_id.id!=='' && goal.sgpParentDoc_id === sgpParentDoc_id.id)){
                                    result.push(goal);
                                } else if ((direction_id.id==='' && indicator_id.id==='' && sgpParentDoc_id.id==='') && (statKato_id.id!=='' && goal.statKato_id === statKato_id.id)){
                                    result.push(goal);
                                }
                            }
                        }
                    }
                    return result;
                },

                getFilteredIndicator(goalIndicatorArr, direction_id, goals_id, sgpParentDoc_id, statKato_id){
                    const result = [];
                    if (goalIndicatorArr.length > 0){
                        if (direction_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'goal'));
                        } else if (goals_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'goal_indicator'));
                        } else if (sgpParentDoc_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'sgpProgram'));
                        } else if (statKato_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'regObl'));
                        }
                        for (const indicator of goalIndicatorArr){
                            if (direction_id.id==='' && goals_id.id==='' && sgpParentDoc_id.id==='' && statKato_id.id===''){
                                result.push(indicator);
                            } else {
                                if ((direction_id.id!=='' && indicator.direction_id === direction_id.id)
                                    &&
                                    (goals_id.id!=='' && indicator.goals_id === goals_id.id)
                                    &&
                                    (sgpParentDoc_id.id!=='' && indicator.sgpParentDoc_id===sgpParentDoc_id.id)
                                    &&
                                    (statKato_id.id!=='' && indicator.statKato_id===statKato_id.id))
                                {
                                    result.push(indicator);
                                } else if ((goals_id.id==='' && sgpParentDoc_id.id==='' && statKato_id.id==='') && (direction_id.id!=='' && indicator.direction_id === direction_id.id)){
                                    result.push(indicator);
                                } else if ((direction_id.id==='' && sgpParentDoc_id.id==='' && statKato_id.id==='') && (goals_id.id!=='' && indicator.goals_id === goals_id.id)){
                                    result.push(indicator);
                                } else if ((goals_id.id==='' && direction_id.id==='' && statKato_id.id==='') && (sgpParentDoc_id.id!=='' && indicator.sgpParentDoc_id === sgpParentDoc_id.id)){
                                    result.push(indicator);
                                } else if ((goals_id.id==='' && direction_id.id==='' && sgpParentDoc_id.id==='') && (statKato_id.id!=='' && indicator.statKato_id === statKato_id.id)){
                                    result.push(indicator);
                                }
                            }
                        }
                    }
                    return result;
                },

                getFilteredSgpParentDoc(programArr, direction_id, goals_id, indicator_id, statKato_id) {
                    const result = [];
                    if (programArr.length>0){
                        if (direction_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'goal'));
                        } else if (goals_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'goal_indicator'));
                        } else if (indicator_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'sgpProgram'));
                        } else if (statKato_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'regObl'));
                        }
                        for (const sgp of programArr){
                            if (direction_id.id==='' && goals_id.id==='' && indicator_id.id==='' && statKato_id.id===''){
                                result.push(sgp);
                            } else {
                                if ((direction_id.id!=='' && sgp.direction_id === direction_id.id)
                                    &&
                                    (goals_id.id!=='' && sgp.goals_id === goals_id.id)
                                    &&
                                    (indicator_id.id!=='' && sgp.indicator_id===indicator_id.id)
                                    &&
                                    (statKato_id.id!=='' && sgp.statKato_id===statKato_id.id))
                                {
                                    result.push(sgp);
                                } else if ((goals_id.id==='' && indicator_id.id==='' && statKato_id.id==='') && (direction_id.id!=='' && sgp.direction_id === direction_id.id)){
                                    result.push(sgp);
                                } else if ((direction_id.id==='' && indicator_id.id==='' && statKato_id.id==='') && (goals_id.id!=='' && sgp.goals_id === goals_id.id)){
                                    result.push(sgp);
                                } else if ((direction_id.id==='' && goals_id.id==='' && statKato_id.id==='') && (indicator_id.id!=='' && sgp.indicator_id === indicator_id.id)){
                                    result.push(sgp);
                                } else if ((direction_id.id==='' && goals_id.id==='' && indicator_id.id==='') && (statKato_id.id!=='' && sgp.statKato_id === statKato_id.id)){
                                    result.push(sgp);
                                }
                            }
                        }
                    }
                    return result;
                },

                getFilteredOblRegions(oblRegArr, direction_id, goals_id, indicator_id, sgpParentDoc_id) {
                    const result = [];
                    if (oblRegArr.length > 0){
                        if (direction_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'goal'));
                        } else if (goals_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'goal_indicator'));
                        } else if (indicator_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'sgpProgram'));
                        } else if (sgpParentDoc_id.id!==''){
                            result.push(this.setNameLangs(this.all, 'regObl'));
                        }
                        for (const region of oblRegArr){
                            if (direction_id.id==='' && goals_id.id==='' && indicator_id.id==='' && sgpParentDoc_id.id===''){
                                result.push(region);
                            } else {
                                if ((direction_id.id!=='' && region.direction_id === direction_id.id)
                                    &&
                                    (goals_id.id!=='' && region.goals_id === goals_id.id)
                                    &&
                                    (indicator_id.id!=='' && region.indicator_id===indicator_id.id)
                                    &&
                                    (sgpParentDoc_id.id!=='' && region.sgpParentDoc_id===sgpParentDoc_id.id))
                                {
                                    result.push(region);
                                } else if ((goals_id.id==='' && indicator_id.id==='' && sgpParentDoc_id.id==='') && (direction_id.id!=='' && region.direction_id === direction_id.id)){
                                    result.push(region);
                                } else if ((direction_id.id==='' && indicator_id.id==='' && sgpParentDoc_id.id==='') && (goals_id.id!=='' && region.goals_id === goals_id.id)){
                                    result.push(region);
                                } else if ((direction_id.id==='' && goals_id.id==='' && sgpParentDoc_id.id==='') && (indicator_id.id!=='' && region.indicator_id === indicator_id.id)){
                                    result.push(region);
                                } else if ((direction_id.id==='' && goals_id.id==='' && indicator_id.id==='') && (sgpParentDoc_id.id!=='' && region.sgpParentDoc_id === sgpParentDoc_id.id)){
                                    result.push(region);
                                }
                            }
                        }
                    }
                    return result;
                },

                setPasspParams(){
                    this.prgId = this.currentProgramPassport.programId;
                    this.prgPasspId = this.currentProgramPassport.programPassportId;
                    this.oblast = this.currentProgramPassport.obl;
                    this.currentProgramPassport.chosenYear = this.chosenYear;
                    const currentYear = new Date().getFullYear();
                    const startDate = new Date(this.currentProgramPassport.startDate).getFullYear();
                    const endDate = new Date(this.currentProgramPassport.endDate).getFullYear();
                    // console.log('ti-filter prgId: ' + this.prgId + ' this.prgPasspId: ' + this.prgPasspId + ' startDate: ' + startDate + ' endDate: ' + endDate);
                    this.listOfYears(currentYear, startDate, endDate);
                    this.chgParams();
                },

                loadOblRegFilter(forecastArr){
                    let regionsList = [];
                    const result = [];
                    if (forecastArr.length > 0) {
                        for (const forecast of forecastArr){
                            if ((forecast.isOblast === false && forecast.parent_id!==0) || (forecast.isOblast === true)) {
                                regionsList.push(forecast.statKato);
                            }
                        }
                        regionsList = this.getOnlyObjects(regionsList);
                        result.push(this.setNameLangs(this.all, 'regObl'));
                        for (const el of regionsList) {
                            result.push(this.setNameLangs(el, 'regObl'))
                        }
                        if (this.dictOblRegVal !== null) {
                            this.dictOblRegVal = this.setNameLangs(this.all, 'regObl');
                            this.dictOblRegVal = this.setNameLangs(this.dictOblRegVal, 'regObl');
                        } else {
                            if (result.length > 0) {
                                this.dictOblRegVal = result[0];
                            }
                        }
                        this.oblRegArr = result;
                        this.oblRegArrInit = result;
                    }
                },

                listOfForecast(forecastArr){
                    this.mainArrForFilter = [];
                    this.mainArrForFilter = Object.assign([], forecastArr);
                    this.loadOblRegFilter(forecastArr);
                    this.initialFillingsOfFilters(forecastArr);
                },

                initialFillingsOfFilters(forecastArr){
                    // console.log('forecastArr: ' + JSON.stringify(forecastArr));
                    this.directionArr = [];
                    this.programGoalArr = [];   //---массив для справочника Целей
                    this.goalIndicatorArr = [];
                    this.programArr = [];       //---Вышестоящий документ
                    // this.oblRegArr = [];
                    const directionArr = [];
                    const programGoalArr = [];
                    const goalIndicatorArr = [];
                    const programArr = [];
                    const oblRegArr = [];
                    if (forecastArr.length > 0){
                        for (const forecast of forecastArr){
                            directionArr.push(forecast.direction);
                            programGoalArr.push(forecast.goals);
                            goalIndicatorArr.push(forecast.indicator);
                            programArr.push(forecast.sgpParentDoc);
                            oblRegArr.push(forecast.statKato);
                        }
                    }

                    this.directionArr = Object.assign([], this.direcationList(directionArr));
                    this.programGoalArr = Object.assign([], this.programGoalArrList(programGoalArr));
                    this.goalIndicatorArr = Object.assign([], this.goalIndicatorArrList(goalIndicatorArr));
                    this.programArr = Object.assign([], this.programArrList(programArr));
                    // this.oblRegArr = this.oblRegArrList(oblRegArr);

                    this.directionArrInit = Object.assign([], this.direcationList(directionArr));
                    this.programGoalArrInit = Object.assign([], this.programGoalArrList(programGoalArr));
                    this.goalIndicatorArrInit = Object.assign([], this.goalIndicatorArrList(goalIndicatorArr));
                    this.programArrInit = Object.assign([], this.programArrList(programArr));
                    // this.oblRegArrInit = this.oblRegArrList(oblRegArr);
                },

                programGoalArrList(programGoalArr){
                    let result = [];
                    if (programGoalArr.length > 0) {
                        result.push(this.setNameLangs(this.all, 'goal'));
                        for (const goals of programGoalArr) {
                            result.push(this.setNameLangs(goals, 'goal'));   //---Создается массив с элементом 'goal'
                        }
                        if (this.dictProgramGoalVal !== null) {    //---должен отображаться выбранный элемент из справочника
                            this.dictProgramGoalVal = this.setNameLangs(this.dictProgramGoalVal, 'goal');
                        } else {
                            if (result.length>0){
                                this.dictProgramGoalVal = result[0];
                            }
                        }
                        result = this.getOnlyObjects(result);
                    } else { //---Если запрос пуст в базе, то происходит очистка параметров
                        this.dictProgramGoalVal = null;
                    }
                    return result;
                },

                direcationList(directionArr){
                    let result = [];
                    if (directionArr.length > 0) {
                        result.push(this.setNameLangs(this.all, 'direction'));
                        for (const el of directionArr) {
                            result.push(this.setNameLangs(el, 'direction'));
                        }
                    }
                    // console.log('this.dictDirectionVal: ' + JSON.stringify(this.dictDirectionVal));
                    if (this.dictDirectionVal !== null) {
                        this.dictDirectionVal = this.setNameLangs(this.all, 'direction');
                        this.dictDirectionVal = this.setNameLangs(this.dictDirectionVal, 'direction');
                    } else {
                        if (result.length > 0) {
                            this.dictDirectionVal = result[0];
                        }
                    }
                    result = this.getOnlyObjects(result);
                    return result;
                },

                goalIndicatorArrList(goalIndicatorArr){
                    let result = [];
                    if (Object.keys(goalIndicatorArr).length !== 0) {
                        // console.log('response: ' + JSON.stringify(response));
                        if (goalIndicatorArr.length > 0) {
                            result.push(this.setNameLangs(this.all, 'goal_indicator'));
                            for (const goalsIndicator of goalIndicatorArr) {
                                result.push(this.setNameLangs(goalsIndicator, 'goal_indicator'));   // ---Создается массив с элементом 'goal'
                            }
                            if (this.dictGoalIndicatorVal !== null && this.dictGoalIndicatorVal !== '') {    // ---должен отображаться выбранный элемент из спрвочника
                                this.dictGoalIndicatorVal = this.setNameLangs(this.dictGoalIndicatorVal, 'goal_indicator');
                            } else {
                                if (result.length > 0){
                                    this.dictGoalIndicatorVal = result[0];
                                }
                            }
                            result = this.getOnlyObjects(result);
                        } else {
                            this.goalIndicatorArr = null;
                        }
                    }
                    return result;
                },

                programArrList(programArr){
                    let result = [];
                    if (programArr.length > 0) {
                        result.push(this.setNameLangs(this.all, 'sgpProgram'));
                        for (const el of programArr) {
                            if (el!==null && JSON.stringify(el) !== "{}") result.push(this.setNameLangs(el, 'sgpProgram'));
                        }
                        if (this.dictProgramVal !== null) {
                            this.dictProgramVal = this.setNameLangs(this.all, 'sgpProgram');
                            this.dictProgramVal = this.setNameLangs(this.dictProgramVal, 'sgpProgram');
                        } else {
                            if (result.length > 0) {
                                this.dictProgramVal = result[0];
                            }
                        }
                        result = this.getOnlyObjects(result);
                    } else {
                        this.dictProgramVal = null;
                    }
                    return result;
                },
                // -----Функция для очистки массива от дубликатов
                getOnlyObjects(arr) {
                    const filteredObjectArr = arr.reduce((enteredArr, current) => {
                        const x = enteredArr.find(item => item.id === current.id);
                        if (!x) {
                            return enteredArr.concat([current]);
                        } else {
                            return enteredArr;
                        }
                    }, []);
                    return filteredObjectArr;
                },

                setNameLangs(obj, codeName) {
                    let txt = '';
                    if (obj!==undefined) {
                        if (codeName === 'npa') {
                            txt = obj.nameRu;
                        } else {
                            txt = obj.name_ru;
                        }
                        if (txt !== undefined) {
                            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                                txt = obj[codeName] + ' - ' + txt;
                            }
                        }
                    }
                    const el = Object.assign({}, obj);
                    el.name = txt;
                    return el;
                },
                // ------Метод для отображения сообщения с ошибками----//
                makeToast(variant, title, tostbody) {
                    this.$bvToast.toast(tostbody, {
                        title: title,
                        autoHideDelay: 5000,
                        variant: variant,
                        appendToast: true
                    });
                } // сообщение с ошибкой
            }
        }
    </script>