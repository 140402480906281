    <template>
        <div class="inner-container">
            <b-tabs>
                <b-tab title="Паспорт" active>
                    <div class="actions-tab in-tab">
                        <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <div class="btn-download">
                                    <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                        <i class="icon icon-keyboard"></i>
                                    </div>
                                </div>
                            </template>
    <!--                        <b-dropdown-text>
                                <div class="info-button">
                                    <span @click="infoCostForm(25)">Режим «Взаимоувязка Целевых индикаторов и Бюджетных программ» <i class="icon icon-folder"></i></span>
                                </div>
                                <div class="info-text">
                                    <p><span class="red bold">Раздел «Целевые Индикаторы»</span>
                                        <br><br>Раздел «Целевые Индикаторы» предназначен для создания и редактирования взаимоувязки Целевых индикаторов
                                        и Бюджетных программ с отображением плановых значений индикаторов в разрезе стратегических документов
                                        в соответствии с Постановлением Правительства Республики Казахстан от 29 ноября 2017 года №790.</p>
                                </div>
                            </b-dropdown-text>-->
                        </b-dropdown>
                    </div>
                    <template>
                        <d-passport-of-program :currentProgramPassport="currentProgramPassport" @closePassport="closePassport"/>
                    </template>
                </b-tab>
                <b-tab title = "Целевые индикаторы">
                    <div class="actions-tab in-tab">
                        <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <div class="btn-download">
                                    <div>
                                            <span class="left">
                                                <i class="icon icon-info"></i>
                                            </span>
                                        <i class="icon icon-keyboard"></i>
                                    </div>
                                </div>
                            </template>
                        </b-dropdown>
                    </div>
                    <template>
                        <d-target-indicator :currentProgramPassport="currentProgramPassport"/>
                    </template>
                </b-tab>
    <!--            <b-tab title="Мероприятия">-->
    <!--                <Events/>-->
    <!--            </b-tab>-->
    <!--            <b-tab title="Оценка эффективности">-->
    <!--                <Efficiency/>-->
    <!--            </b-tab>-->
            </b-tabs>
        </div>
    </template>

    <script>
    import 'bootstrap/dist/css/bootstrap.css';
    import 'bootstrap-vue/dist/bootstrap-vue.css';
    import DPassportOfProgram from '@/modules/development-program/PassportOfProgram.vue';
    import Indicators from '@/modules/development-program/tabs/indicators/indicators';
    // import Events from '@/modules/development-program/tabs/Events';
    // import Efficiency from '@/modules/development-program/tabs/Efficiency';

    export default {
        name: "formOfProgramModules",
        components: {
            'd-passport-of-program': DPassportOfProgram,
            'd-target-indicator': Indicators
            // ,
            // Events,
            // Efficiency
        },
        props: {
            currentProgramPassport: {
                required: true,
                default: null
            }
        },
        created(){
        },
        mounted() {
        },
        data() {
            return {
            }
        },
        methods: {
            closePassport(close){
                this.$emit('closePassport', close);
            }
        },
        computed: {
        }
    }
    </script>

    <style scoped>

    </style>